.four-up-component {
	padding: 20px 0 40px;

	.inner {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.column {
		width: 100%;
		text-align: center;
		padding: 0 25px;
		margin: 20px 0;

		img {
			margin: 0 auto 15px;
			//temp
			height: 40px;
		}
		h1,h2,h3,h4,h5,h6 {
			color: $light-red;
			padding: 0 10px;
			line-height: 1.3;
			margin-bottom: 10px;
		}
		p {
			margin-bottom: 0;
			line-height: 1.5;
			a {
				font-weight: bold;
			}
		}
	}

	.region-benefits {
		width: 100%;
		margin-top: 40px;
		padding: 40px 20px;
		text-align: center;
		background: $alt-dark-blue url(../images/wavy-wall.png) repeat center center;
		position: relative;
		&:after{
			top: 0%;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(255, 255, 255, 0);
			border-top-color: $white;
			border-width: 15px;
			margin-left: -15px;
		}
		h3 {
			display: inline-block;
			color: $white;
			margin: 0px 5px 10px 0px;
			line-height: 1.2;
		}
		select {
			display: inline-block;
			width: auto;
			margin: 10px 0 0 0;
		}
	}

	@include from(6){

		.column {
			width: 50%;
			border-right: 1px solid $silver-gray;
			&:nth-of-type(2){
				border-right: transparent;
			}
			&:nth-of-type(4){
				border-right: transparent;
			}
		}

	}

	@include from(10){
		padding: 40px 0;

		.column {
			width: 25%;
			&:nth-of-type(2){
				border-right: 1px solid $silver-gray;
			}
		}

	}

}