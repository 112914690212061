.sponsor-wrapper {

  .sponsor-item{
    padding: 20px 0;
    border-bottom: 1px solid $silver-gray;

    &:first-of-type{
      padding-top: 0;
    }

    &:last-of-type{
      margin-bottom: 15px;
    }

    .eyebrow {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
      @include rem(font-size, 14px);
    }

    a,
    p a {
      @include rem(font-size, 14px);
    }

    img {
      margin: 10px auto;
    }

  }

}