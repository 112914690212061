a.btn,
button,
input[type="submit"],
input[type="button"] {

  &.-is-loading {
    padding-right: 40px;
    cursor: default;

    &:hover,
    &:focus {
      background-color: $light-red;
      color: $white;
    }
  }
}

.-is-loading,
.-is-loading-submit {
  position: relative;

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 10px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 3px solid rgba($white, .85);
    border-top-color: transparent;
    animation: spinner .8s linear infinite;
  }
}

.-is-loading-submit {
  display: inline-block;

  input[type='submit'] {
    padding-right: 40px;
    cursor: default;

    &:hover,
    &:focus {
      background-color: $light-red;
      color: $white;
    }
  }
}

div,
section,
aside {

  &.-is-loading {

    &::after {
      top: 10%;
      right: auto;
      left: calc(50% - 15px);
      width: 30px;
      height: 30px;
      margin-top: 0;
      margin-left: 0;
      border-radius: 50%;
      border: 5px solid $light-red;
      border-top-color: transparent;
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
