.agent-finder-results {
  margin: 0 0 30px;

  @include from(10) {
    margin: 0 0 50px;
  }

  .agent-finder-listing {
    padding: 10px 10px 20px;
    margin-bottom: 15px;
    color: $darkest-gray;
    border-left: 3px solid transparent;
    @include transition(border-color);

    @include from(10) {
      margin-bottom: 0;

      &:hover {
        border-left: 3px solid $light-red;
      }
    }

    .name {
      margin-bottom: 10px;
      color: $dark-blue;

      p {
        margin-bottom: 3px;
        @include rem(font-size, 16px);
        font-weight: 900;
      }
    }

    .email-phone {
      margin-bottom: 10px;
    }

    .certifications {
      display: flex;
    }

    @include from(12) {
      padding: 20px 10px;

      .name {
        margin-bottom: 5px;
      }

      .email-phone {
        flex: 1 1 auto;
        width: 30%;
        margin-bottom: 0;
      }

      .email-phone,
      .agency {
        padding-right: 10px;
      }

      .address {
        flex: 1 1 auto;
        width: 30%;
        padding-right: 10px;
      }

      .contact,
      .agency {
        flex: 1 1 auto;
        width: 15%;
      }
    }

    @include from(13) {
      padding: 25px 20px;
    }

    &:nth-child(even) {
      background: $light-blue-gray;
    }

    p {
      @include rem(font-size, 13px);
      margin: 0;
    }

    .rating,
    .label {
      @include from(12) {

        span {
          display: block;

          &.specialist {
            margin: 8px 0 0;
          }
        }
      }
    }

    .address {

      p {
        margin-bottom: 10px;

        @include from(12) {
          margin-bottom: 0;
        }
      }
    }

    .agency-name {
      font-weight: 900;
    }

    .contact {
      display: flex;
      margin: 20px 0 0;

      @include from(12) {
        justify-content: flex-end;
        margin: 0;
        padding: 5px 0 0;
      }

      a {
        display: inline-block;
        margin-right: 10px;
        width: 35px;
        height: 35px;
        background-color: $marina-blue;
        text-align: center;
        text-decoration: none;
        font-size: 0;
        color: $white;
        border-radius: 50%;
        @include transition(background-color);

        &::before {
          line-height: 35px;
          font-size: 16px;
        }

        &:hover {
          background-color: $port-blue;
        }

        @include from(12) {
          margin: 0 0 0 5px;
        }
      }
    }

    .label {
      margin-bottom: 5px;

      @include from(12) {
        margin-bottom: 0;
      }

      .agency-label {
        // max-width: 65px;
        display: inline-block;
        padding: 4px 10px;
        background-color: $dark-blue;
        text-align: center;
        text-transform: uppercase;
        @include rem(font-size, 10px);
        color: $white;
        font-weight: 900;

        &.-premier {
          background-color: $marina-blue;
        }

        &.-gold {
          background-color: $orange;
        }

        &.-premier {
          background-color: $light-red;
        }

        &.-premier-consortia {
          background-color: $marina-blue;
        }
      }
    }

  }
}

.agent-finder-listing-inner {

  @include from(12) {
    display: flex;
  }
}


.agent-finder-results,
.agent-finder-legend {

  .star,
  .specialist {
    font-size: 0;

    &::before {
      font-size: 14px;
    }
  }

  .star {

    &::before {
      color: $orange;
    }
  }

  .specialist {
    margin-left: 5px;

    &::before {
      color: #3999bb;
    }
  }
}

.agent-finder-legend {
  padding: 40px 0 30px;
  margin: 40px 0 0;
  border-top: 1px solid #e7e8ed;
  border-bottom: 1px solid #e7e8ed;

  @include from(8) {
    border-top: 0;
    margin: 0;
    padding: 20px 0 0;
  }

  @include from(10) {
    padding: 50px 0 0;
  }

  .star,
  .specialist {
    display: block;
    margin-bottom: 5px;
  }

  .icon-diamond {
    margin: 0;
  }

  p {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    @include rem(font-size, 13px);
    font-weight: 900;

    img {
      flex: 0 0 auto;
      margin-right: 5px;
    }
  }
}

.agent-finder-about {
  padding-top: 30px;

  h5 {
    margin-bottom: 10px;
    @include rem(font-size, 12px);
    color: $medium-gray;
  }

  p {
    @include rem(font-size, 14px);
    margin-bottom: 10px;
  }

  .learn-more {
    font-weight: 900;
  }

  .jump-to-search-results {
    margin: 30px 0 0;
    width: 100%;

    @include from(6) {
      width: auto;
    }

    @include from(8) {
      display: none;
    }
  }

}

.agent-no-results,
.search-no-results,
.search-no-keywords {

  &.result {
    border-bottom: 0;
  }

  @include from(7) {
    padding: 40px 0;
  }

  @include from(10) {
    padding: 60px 0;
  }

  .search-within-distance {

    @include from(10) {
      max-width: 75%;

    }

    label {
      color: $darkest-gray;
    }

    .of {
      display: block;
      margin: 0 0 20px;

      @include from(7) {
        margin: 0;
      }
    }

    input[type="submit"] {
      margin: 10px 0 0;
      width: 100%;

      @include from(7) {
        margin: 0;
        width: auto;
      }
    }

    @include from(7) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      label,
      select,
      .of,
      input[type='text'],
      input[type='submit'] {
        flex: 1 1 auto;
      }

      label {
        white-space: nowrap;
        margin: 0 20px 0 0;
      }

      .of {
        text-align: center;
        margin: 0 10px;
      }

      input[type='text'] {
        min-width: 100px;
      }

      input[type='submit'] {
        min-width: 180px;
        margin-left: 20px;
      }

      input,
      select {
        margin: 0;
      }
    }

  }
}


// OLD VERSION (temp keeping in case they want to revert last minute)
// .agent-finder-results {
//   margin: 0 0 30px;
//
//   @include from(10) {
//     margin: 0 0 50px;
//   }
//
//   .agent-finder-listing {
//     padding: 10px 10px 20px;
//     margin-bottom: 15px;
//     color: $darkest-gray;
//     border-left: 3px solid transparent;
//     @include transition(border-color);
//
//     @include from(10) {
//       margin-bottom: 0;
//
//       &:hover {
//         border-left: 3px solid $light-red;
//       }
//     }
//
//     @include from(12) {
//       display: flex;
//       padding: 20px 10px;
//
//       .rating,
//       .label {
//         width: 12%;
//         padding: 5px 0 0;
//       }
//
//       .name {
//         p {
//           line-height: 1.35;
//         }
//       }
//
//       .name,
//       .agency {
//         width: 21%;
//         padding-right: 10px;
//       }
//
//       .address {
//         width: 28%;
//         padding-right: 10px;
//       }
//
//       .contact {
//         width: 18%;
//       }
//     }
//
//     @include from(13) {
//       padding: 45px 20px;
//     }
//
//     &:nth-child(even) {
//       background: $light-blue-gray;
//     }
//
//     p {
//       @include rem(font-size, 13px);
//       margin: 0;
//     }
//
//     .rating,
//     .label {
//       @include from(12) {
//
//         span {
//           display: block;
//
//           &.specialist {
//             margin: 8px 0 0;
//           }
//         }
//       }
//     }
//
//     .name {
//       color: $dark-blue;
//
//       p {
//         @include rem(font-size, 16px);
//         font-weight: 900;
//       }
//     }
//
//     .address {
//
//       p {
//         margin-bottom: 10px;
//
//         @include from(12) {
//           margin-bottom: 0;
//         }
//       }
//     }
//
//     .agency-name {
//       font-weight: 900;
//     }
//
//     .contact {
//       display: flex;
//       margin: 20px 0 0;
//
//       @include from(12) {
//         justify-content: flex-end;
//         margin: 0;
//         padding: 5px 0 0;
//       }
//
//       a {
//         display: inline-block;
//         margin-right: 10px;
//         width: 35px;
//         height: 35px;
//         background-color: $marina-blue;
//         text-align: center;
//         text-decoration: none;
//         font-size: 0;
//         color: $white;
//         border-radius: 50%;
//         @include transition(background-color);
//
//         &::before {
//           line-height: 35px;
//           font-size: 16px;
//         }
//
//         &:hover {
//           background-color: $port-blue;
//         }
//
//         @include from(12) {
//           margin: 0 0 0 5px;
//         }
//       }
//     }
//
//     .label {
//       margin-bottom: 5px;
//
//       @include from(12) {
//         margin-bottom: 0;
//       }
//
//       .agency-label {
//         max-width: 65px;
//         padding: 5px;
//         background-color: $dark-blue;
//         text-align: center;
//         @include rem(font-size, 10px);
//         color: $white;
//         font-weight: 900;
//
//         &.-premier {
//           background-color: $marina-blue;
//         }
//       }
//     }
//
//   }
// }
//
//
// .agent-finder-results,
// .agent-finder-legend {
//
//   .star,
//   .specialist {
//     font-size: 0;
//
//     &::before {
//       font-size: 14px;
//     }
//   }
//
//   .star {
//
//     &::before {
//       color: $orange;
//     }
//   }
//
//   .specialist {
//     margin-left: 5px;
//
//     &::before {
//       color: #3999bb;
//     }
//   }
// }
//
// .agent-finder-legend {
//   padding: 40px 0 0;
//   margin: 40px 0 0;
//   border-top: 1px solid #e7e8ed;
//   border-bottom: 1px solid #e7e8ed;
//
//   @include from(8) {
//     border-top: 0;
//     margin: 0;
//     padding: 20px 0 0;
//   }
//
//   @include from(10) {
//     padding: 50px 0 0;
//   }
//
//   .star,
//   .specialist {
//     display: block;
//     margin-bottom: 5px;
//   }
//
//   .icon-diamond {
//     margin: 0;
//   }
//
//   p {
//     @include rem(font-size, 13px);
//     font-weight: 900;
//   }
// }
//
// .agent-finder-about {
//   padding-top: 30px;
//
//   h5 {
//     margin-bottom: 10px;
//     @include rem(font-size, 12px);
//     color: $medium-gray;
//   }
//
//   p {
//     @include rem(font-size, 14px);
//     margin-bottom: 10px;
//   }
//
//   .learn-more {
//     font-weight: 900;
//   }
//
//   .jump-to-search-results {
//     margin: 30px 0 0;
//     width: 100%;
//
//     @include from(6) {
//       width: auto;
//     }
//
//     @include from(8) {
//       display: none;
//     }
//   }
//
// }
//
// .agent-no-results,
// .search-no-results,
// .search-no-keywords {
//
//   &.result {
//     border-bottom: 0;
//   }
//
//   @include from(7) {
//     padding: 40px 0;
//   }
//
//   @include from(10) {
//     padding: 60px 0;
//   }
//
//   .search-within-distance {
//
//     @include from(10) {
//       max-width: 75%;
//
//     }
//
//     label {
//       color: $darkest-gray;
//     }
//
//     .of {
//       display: block;
//       margin: 0 0 20px;
//
//       @include from(7) {
//         margin: 0;
//       }
//     }
//
//     input[type="submit"] {
//       margin: 10px 0 0;
//       width: 100%;
//
//       @include from(7) {
//         margin: 0;
//         width: auto;
//       }
//     }
//
//     @include from(7) {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       width: 100%;
//
//       label,
//       select,
//       .of,
//       input[type='text'],
//       input[type='submit'] {
//         flex: 1 1 auto;
//       }
//
//       label {
//         white-space: nowrap;
//         margin: 0 20px 0 0;
//       }
//
//       .of {
//         text-align: center;
//         margin: 0 10px;
//       }
//
//       input[type='text'] {
//         min-width: 100px;
//       }
//
//       input[type='submit'] {
//         min-width: 180px;
//         margin-left: 20px;
//       }
//
//       input,
//       select {
//         margin: 0;
//       }
//     }
//
//   }
// }
