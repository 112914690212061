.header-section {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .content-wrapper {
    .eyebrow {
      margin-bottom: 7px;
    }

    h1 {
      margin-bottom: 0;
      letter-spacing: .04em;
      line-height: 1.1;
    }
  }

  .large-text-content {
    text-align: center;
    max-width: 920px;
    margin: auto;
    padding: 40px 20px;
    width: 100%;

    .eyebrow {
      margin-bottom: 10px;
    }

    h1 {
      text-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }

    p {
      margin-bottom: 0;

      + .btn,
      + button,
      + p {
        margin-top: 25px;
      }
    }

    a:not(.btn) {
      color: $white;
      font-weight: bold;
      text-decoration: underline;
    }

    .btn + *,
    button + * {
      margin-top: 25px;
    }
  }

  &.-generic {
    background-color: $marina-blue;
    text-align: center;

    .content-wrapper {
      padding: 30px 20px 40px;
      max-width: 760px;
      margin: auto;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: $white;
      }

      .search {
        margin-top: 20px;
        position: relative;

        input {
          margin-bottom: 0;
          padding-right: 60px;
        }

        a {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 50px;
          color: $medium-gray;
          @include rem(font-size, 18px);
          transition: all $ts $te;

          &:before {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
          }

          &:hover {
            text-decoration: none;
            background-color: $light-gray;
            color: $white;
            border-radius: 0 $border-radius $border-radius 0;
          }
        }
      }
    }
  }

  &.-small {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    @include from(8) {
      height: 380px;
    }
  }

  &.-medium {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    @include from(8) {
      height: 450px;
    }
  }

  &.-large {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 450px;
    @include from(9) {
      height: 660px;
    }
  }

  &.-nav-list {
    .bottom-nav-wrapper {
      display: inline-block;
      width: 100%;

      ul {
        display: none;
        list-style: none;
        text-align: center;
        margin: 0;
        padding: 0;

        li {
          margin: 0 10px;
          display: inline-block;

          a {
            display: block;
            padding: 20px 0;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            color: rgba($white, 0.75);
            transition: all $ts $te;

            &.-active,
            &:hover {
              border-top-color: $light-red;
              border-bottom-color: $light-red;
              color: $white;
              text-decoration: none;
            }
          }
        }
      }

      .mobile-list {
        display: block;
        margin: 25px 0;
      }

      @include from(8) {
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .mobile-list {
          display: none;
        }
      }

      @include from(10) {
        ul {
          li {
            margin: 0 20px;
          }
        }
      }

      @include from(12) {
        ul {
          li {
            margin: 0 40px;
          }
        }
      }
    }

    &.-my-clia {
      .content-wrapper {
        width: 100%;
        padding: 40px 20px 20px;

        .user-wrapper {
          display: flex;
          align-items: center;
          padding-right: 10px;

          img {
            border-radius: 50%;
            margin-right: 20px;
            max-width: 150px;
            width: 25%;
            border: 4px solid #1186c2;
          }

          .user-name {
            max-width: 100%;
            width: 75%;

            h3,
            span {
              display: inline-block;
              color: $white;
              width: 100%;
              font-weight: 900;
              letter-spacing: .04em;
            }

            h3 {
              // to restrict characters for long names
              max-width: 90%;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-bottom: 3px;
              line-height: 1.2;
              -ms-word-break: break-all;
              // to make up for bottom padding and line height
              margin-bottom: -5px;
            }

            span {
              @include rem(font-size, 13px);
            }
          }
        }

        .user-info {
          padding-top: 20px;

          .text-item {
            padding: 15px 0;
            border-bottom: 1px solid rgba($white, 0.5);

            p {
              color: $white;
              margin-bottom: 0;
              @include rem(font-size, 13px);

              span {
                display: block;
                @include rem(font-size, 18px);
                font-weight: 900;
                letter-spacing: .05em;
              }

              a {
                color: $white;
                padding-bottom: 1px;
                border-bottom: 1px solid rgba($white, 0.5);
                transition: border $ts $te;

                &:focus,
                &:hover {
                  text-decoration: none;
                  border-bottom-color: $white;
                }
              }
            }

            &:last-of-type {
              border-bottom: none;
            }
          }
        }
      }

      .bottom-nav-wrapper {
        img {
          display: none;
        }
      }

      @include from(5) {
        .content-wrapper {
          .user-info {
            display: flex;

            .text-item {
              width: 33%;
              padding: 5px 15px;
              border-left: 1px solid rgba($white, 0.5);
              border-bottom: none;
            }
          }
        }
      }

      @include from(8) {
        .content-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 20px;

          .user-wrapper {
            width: 40%;

            img {
              width: 35%;
            }
          }

          .user-info {
            width: 60%;
            justify-content: flex-end;
            padding-top: 0;
          }
        }

        .bottom-nav-wrapper {
          .inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
          }

          img {
            display: block;
            max-width: 15%;
            width: 100%;
            align-self: center;
          }

          ul {
            justify-content: flex-end;

            li {
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    &.-blue-side-box {
      .right {
        background-color: transparent;

        ul {
          margin: 0;
          padding: 0;

          li {
            list-style: none;
            margin-bottom: 10px;
            background-color: rgba($dark-blue, 0.8);
            padding: 20px 70px 20px 20px;
            position: relative;
            transition: background-color .4s $te;

            i {
              display: inline-block;
              margin-right: 3px;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              margin-bottom: 0;
              display: inline-block;

              + p {
                margin-top: 5px;
              }
            }

            p {
              margin-bottom: 0;
            }

            a {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              border-bottom: 0;

              &:before {
                font-family: $icon;
                content: '\e903';
                background-color: $dark-blue;
                width: 50px;
                right: 0;
                top: 0;
                bottom: 0;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: width $ts $te;
              }
            }

            &:focus,
            &:hover {
              background-color: $dark-blue;

              a {
                &:before {
                  width: 40px;
                }
              }
            }
          }
        }
      }

      @include from(8) {
        .right {
          width: 35%;
          margin: 0;
          padding-right: 0;

          ul {
            li {}
          }
        }
      }
    }
  }

  &.-dark-bottom {

    @include from(8) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .bottom-nav-wrapper {
      background-color: rgba($dark-blue, 0.8);
    }
  }

  &.-light-bottom {
    justify-content: space-between;
    min-height: auto;

    .content-wrapper {
      background-color: rgba($marina-blue, 0.8);
      padding: 20px;
      text-align: center;
      margin-top: 150px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      li,
      p,
      a:not(.btn) {
        color: $white;
      }

      form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        input,
        select {
          width: auto;
          margin: 10px 0;

          &::-webkit-input-placeholder,
          &::-moz-placeholder,
          &:-ms-input-placeholder,
          &:-moz-placeholder {
            font-weight: 900;
            font-style: normal;
          }
        }

        select {
          font-weight: 900;
        }

        label,
        span {
          color: $white;
          letter-spacing: .02em;
        }

        span {
          display: block;
          margin: 0 8px;
          font-weight: 400;
          font-style: italic;
        }

        button {
          margin: 10px 0 10px 10px;
        }
      }

      @include from(8) {
        margin-top: auto;
        padding: 30px 40px;

        form {
          select {
            padding: 10px 50px 10px 10px;
          }

          input {
            padding: 10px 15px;
          }

          span {
            margin: 0 10px;
          }

          button {
            margin-left: 15px;
          }
        }
      }
    }
  }

  &.-blue-side-box {
    .content-wrapper {
      padding-top: 40px;
      width: 100%;
    }

    .left {
      margin-bottom: 40px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      li,
      p,
      a:not(.btn) {
        color: $white;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      li {
        line-height: 1.3;
      }

      .eyebrow,
      h1 {
        margin-bottom: 10px;
      }
    }

    .right {
      background-color: rgba($dark-blue, 0.8);
      margin-left: -20px;
      width: calc(100% + 40px);
      padding: 30px 20px 10px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      li,
      p,
      a:not(.btn) {
        color: $white;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: 1.3;
        margin-bottom: 15px;
      }

      a:not(.btn) {
        font-weight: 900;
        border-bottom: 1px solid rgba($white, 0.2);
        transition: border $ts $te;

        &:hover {
          text-decoration: none;
          border-bottom-color: rgba($white, 0.5);
        }
      }
    }

    @include from(8) {
      .content-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 40px;
      }

      .left {
        max-width: 680px;
        width: 65%;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-bottom: 0;
      }

      .right {
        width: 32%;
        min-height: 340px;
        padding: 30px 35px;
        margin-left: 0;
      }
    }

    &.-research-detail {
      .left {
        a.btn {
          margin-top: 20px;
        }
      }

      .right {
        background-color: transparent;

        img {
          display: none;
        }
      }

      @include from(7) {
        .content-wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-bottom: 40px;
        }

        .left {
          max-width: 680px;
          width: 65%;
          padding-top: 30px;
          padding-bottom: 20px;
          margin-bottom: 0;
        }

        .right {
          width: 32%;
          min-height: 340px;
          margin-left: 0;
          padding: 0;
          margin-bottom: -100px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;

          img {
            display: flex;
            align-self: flex-end;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  &.-white-side-box {
    background-position: left center;
    background-color: $white;
    min-height: 100px;
    overflow: visible;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 33%;
      background-color: $white;
    }

    .inner {
      width: 100%;
      margin: 100px 0 0;
      padding-bottom: 20px;
    }

    .breadcrumb-wrapper .inner {
      margin: auto;
      padding: 0 20px;
    }

    .text-tile {
      background-color: $white;
      padding: 40px;
      box-shadow: 5px 5px 5px 0 rgba(0,0,0,0.05);
      z-index: +1;
      position: relative;

      .eyebrow {
        color: $light-blue;
        margin-bottom: 15px;
      }
    }
    @include from(8) {
      background-position: center center;
      margin-bottom: 0;

      &:after {
        display: none;
      }

      .inner {
        margin: auto;
        padding-bottom: 0;
      }

      .breadcrumb-wrapper .inner {
        padding: 0 40px;
      }

      .text-tile {
        padding: 40px 60px;
        max-width: 54%;
        margin: auto 0 auto auto;
      }
    }
  }

  &.-slider {
    position: relative;
    height: auto;

    @include from(8) {
      min-height: 450px;
    }

    .breadcrumb-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .content-wrapper {
      margin-top: 0;
      width: 100%;
    }
  }
}
