#nav-source {
  display: none;
}

.js-mobile-menu {
  width: 80%;
  min-width: 270px;
  max-width: 320px;
  position: fixed;
  z-index: 999;
  top: 0;
  height: 100%;
  right: -100%;
  transition: right $ts $te;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  background: $dark-blue;

  .js-mobile-menu__inner {
    position: relative;

    ul {
      transition: left $ts $te;
      position: absolute;
      top: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      border: none;

      li {
        display: flex;
        margin: 0 25px;
        border-bottom: 1px solid rgba($white, 0.2);
        @include rem(font-size, 15px);
        font-weight: 900;

        a {
          white-space: nowrap;
          flex: 1 1 0;
          display: block;
          height: 50px;
          line-height: 50px;
          color: $white;
          text-decoration: none;

          &:first-of-type {
            padding-left: 5px;
          }

          &.subopen {
            width: 48px;
            flex: none;

            &:before {
              content: "\e903";
              display: inline-block;
              width: 48px;
              height: 50px;
              padding-right: 5px;
              text-align: right;
              line-height: 50px;
              font-family: $icon;
            }
          }
        }

        &.mobile-search {
          margin: 0 0 25px;
          padding: 20px;
          background: $fjord-blue;
          border: 0;
          form {
            display: flex;
            width: 100%;
            align-items: center;
          }
          input[type="search"] {
            width: calc(100% - 35px);
            margin: 0;
            background: $fjord-blue;
            border: 0;
            color: #e2e3e7;
            box-shadow: none;
            @include rem(font-size, 15px);

            &::placeholder {
              color: #e2e3e7;
            }

            &:focus {
              background: lighten($fjord-blue, 10%);
            }

          }
          button {
            padding: 10px;
            background: transparent;
            border: 0;
            font-size: 20px;
            color: $white;
          }

        }

        &.mobile-utility {
          margin-top: 25px;
          border: 0;
          @include rem(font-size, 13px);

          a {
            height: 35px;
            line-height: 35px;
            color: rgba($white, 0.5);

            &.subopen {

              &::before {
                height: 35px;
                line-height: 35px;
              }
            }
          }

          & ~ .mobile-utility {
            margin-top: 0;
          }
        }

        ul {
          display: none;
        }

        &.nav-back {
          display: flex;
          margin: 0;
          border: 0;

          a {
            flex: 1 1 0;
            display: block;
            height: 60px;
            line-height: 60px;
            background: rgba($black, 0.2);
            padding-left: 0;

            &:before {
              content: "\e90a";
              width: 48px;
              height: 60px;
              text-align: center;
              display: inline-block;
              line-height: 60px;
              font-family: $icon;
            }
          }

          &:hover {

            a {

              &:last-of-type {
                background: rgba($black, 0.33);
              }
            }
          }
        }
      }
    }
  }
}


// dont edit below
html.-js-menu-open {
  overflow: hidden;

  .js-mobile-menu {
    right: 0;

    .js-mobile-menu__inner {
      position: relative;
    }

    &[data-current="0"] {

      ul[data-position="0"] {
        left: 0;
      }

      ul[data-position="1"] {
        left: 100%;
      }

      ul[data-position="2"] {
        left: 100%;
      }
    }

    &[data-current="1"] {

      ul[data-position="0"] {
        left: -100%;
      }

      ul[data-position="1"] {
        left: 0;
      }

      ul[data-position="2"] {
        left: 100%;
      }
    }

    &[data-current="2"] {

      ul[data-position="0"] {
        left: -100%;
      }

      ul[data-position="1"] {
        left: -100%;
      }

      ul[data-position="2"] {
        left: 0;
      }
    }
  }
}
