.schedule-wrapper {
	padding: 40px 0;

	h2 {
		border-bottom: 1px solid $silver-gray;
		padding-bottom: 20px;
		transition: margin $ts $te;
	}

	.schedule-item {
		padding: 20px 0;

		.time {
			text-transform: uppercase;
			font-weight: 900;
			color: $medium-gray;
			@include rem(font-size, 14px);
			margin-bottom: 10px;
		}

		.schedule-item-content {
			border-bottom: 1px solid $silver-gray;
			padding-bottom: 20px;
			h1,h2,h3,h4,h5,h6 {
				color: $light-red;
				line-height: 1.4;
			}

			img {
				display: none;
			}

			.-sponsor {
				margin-top: -15px;
				@include rem(font-size, 12px);
				font-style: italic;
				color: $medium-gray;
				a,
				span {
					font-style: normal;
					font-weight: bold;
					text-transform: uppercase;
					color: $black;
				}
			}

		}

		+ h2 {
			margin-top: 20px;
		}

	}

	@include from(4) {
		.schedule-item {
			.schedule-item-content {
				img {
					display: block;
					float: right;
					max-width: 100px;
					margin-left: 20px;
					margin-bottom: 20px;
				}
			}
		}
	}

	@include from(6){
		.schedule-item {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			.time {
				width: 18%;
				padding-right: 20px;
				padding-top: 5px;
			}
			.schedule-item-content {
				width: 82%;
			}
		}
	}

	@include from(8){
		padding: 60px 0;
		.schedule-item {
			.time {
				text-align: right;
			}
			+ h2 {
				margin-top: 60px;
			}
		}
	}

	@include from(11) {
		h2 {
			max-width: 920px;
			margin-left: auto;
		}
	}

}