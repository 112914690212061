// Headers
//
// .red       - Red
// .-border   - Bottom border
// .large     - Large
// .small     - Small
//
// Markup:
// <h1 class="{{modifier_class}}">Header 1 - Lorem Ipsum Solor Domet</h1>
// <h2 class="{{modifier_class}}">Header 2 - Lorem Ipsum Solor Domet</h2>
// <h3 class="{{modifier_class}}">Header 3 - Lorem Ipsum Solor Domet</h3>
// <h4 class="{{modifier_class}}">Header 4 - Lorem Ipsum Solor Domet</h4>
// <h5 class="{{modifier_class}}">Header 5 - Lorem Ipsum Solor Domet</h5>
// <h6 class="{{modifier_class}}">Header 6 - Lorem Ipsum Solor Domet</h6>
//
// Styleguide 3.1
h1, h2, h3, h4, h5, h6 {
	margin: 0 0 20px;
	font-family: $header-font;
	font-weight: 900;
	line-height: 1;
	color: $header-color;
	transition: font-size $ts $te;
  letter-spacing: 0.02em;

	&.tight {
  	margin-bottom: 5px;
	}

	&.flush {
  	margin-bottom: 0;
	}

	&.red {
		color: $light-red;
	}

	&.gray {
		color: $dark-gray;
	}

	&.-border {
		padding: 0 0 10px;
		border-bottom: 1px solid $hr-color;
	}

	a {
  	text-decoration: none;

  	&:hover,
    &:focus {
      color: darken($header-color, 10%);
  	}
	}
}

h1 {
  @include rem(font-size, 32px);

  &.large {
    @include rem(font-size, 54px);
  }

  &.small {
    @include rem(font-size, 30px);
  }

  @include from(8) {

    @include rem(font-size, 40px);

    &.large {
      @include rem(font-size, 65px);
    }

    &.small {
      @include rem(font-size, 35px);
    }

  }

}

h2 {
  @include rem(font-size, 26px);

  &.large {
    @include rem(font-size, 30px);
  }

  @include from(8) {

    @include rem(font-size, 30px);

    &.large {
      @include rem(font-size, 35px);
    }

  }
}

h3 {
  @include rem(font-size, 20px);

  &.large {
    @include rem(font-size, 22px);
  }

  @include from(8) {
    @include rem(font-size, 22px);
    &.large {
      @include rem(font-size, 25px);
    }
  }
}

h4 {
  @include rem(font-size, 18px);

  @include from(8) {
    @include rem(font-size, 20px);
  }
}

h5 {
  @include rem(font-size, 17px);

  @include from(8) {
    @include rem(font-size, 18px);
  }
}

h6 {
  @include rem(font-size, 17px);
}


// Paragraphs
//
// .large     - Large
// .small     - Small
//
// Markup:
// <p class="{{modifier_class}}">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
// <p class="{{modifier_class}}">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. </p>
// <p class="{{modifier_class}}">Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum gravida et. Integer lectus nisi, facilisis sit amet eleifend nec, pharetra ut augue. Integer quam nunc, consequat nec egestas ac, volutpat ac nisi. </p>
//
// Styleguide 3.2
p {
  @include rem(font-size, 16px);
  margin: 0 0 25px;
  line-height: 1.7;
  letter-spacing: 0.02em;
  font-weight: normal;

  &.large {
    @include rem(font-size, 17px);

    @include from(8) {
      @include rem(font-size, 18px);
    }
  }

	&.small {
		@include rem(font-size, 13px);

		@include from(8) {
		  @include rem(font-size, 14px);
		}
	}

	&.tight {
		line-height: 1.25;
	}

}

// Eyebrow
//
// .small     - Small
// .gray      - Gray
//
// Markup:
// <p class="eyebrow {{modifier_class}}">Eyebrow Style</p>
//
// Styleguide 3.6
.eyebrow {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.1em;
  color: $dark-blue;
  margin-bottom: 20px;
  @include rem(font-size, 14.5px);

  &.small {
    @include rem(font-size, 12px);
    margin-bottom: 15px;
  }

	&.gray {
		color: $medium-gray;
	}
}

.component-title {
  text-align: center;
  position: relative;
  margin: 0 auto 25px;
  overflow: hidden;
  max-width: 1200px;
  padding: 5px 0;

  span {
    display: inline-block;
    position: relative;

    &::before,
    &::after{
      content: '';
      position: absolute;
      height: 2px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 500px;
      background-color: rgba($black, 0.1);
    }

    &::before{
      right: 100%;
      margin-right: 15px;
    }

    &::after{
      left: 100%;
      margin-left: 15px;
    }
  }
}

// Blockquotes
//
// Markup:
// <blockquote class="testimonial-size">
//   <p>“CLIA represents every aspect of the global travel industry, providing multiple opportunities for networking. Plus, CLIA conferences and events are strongly supported by cruise line executives who make themselves readily available to Executive Partners.”</p>
//   <cite>— <strong>Michael Nerney</strong>, Maritime Marketing Manager, Port of San Francisco</cite>
// </blockquote>
//
// Styleguide 3.5
blockquote {
  color: $light-blue;
  border-left: 3px solid $light-blue;
  @include rem(font-size, 22px);
  margin: 0 0 25px;
  padding: 6px 0px 6px 30px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.4;

	@include from(8) {
	  @include rem(font-size, 25px);
	}

  p {
    color: $light-blue;
    @include rem(font-size, 22px);
    font-style: italic;
    font-weight: normal;
    line-height: 1.4;

		@include from(8) {
		  @include rem(font-size, 25px);
		}

    &:last-of-type{
      margin-bottom: 0;
    }
  }

  cite {
    margin-top: 25px;
    color: $darkest-gray;
    @include rem(font-size, 16px);
    font-style: normal;
    font-weight: 500;
    display: block;
    line-height: 1.4;

    // same thing just in case wysiwyg
    p {
      color: $darkest-gray;
      @include rem(font-size, 16px);
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 1.4;
    }
  }

  &.testimonial-size {
    border-left: 0;
    padding: 0;
  }
}

.pull-quote {
	margin-top: 30px;
	padding-left: 35px;
	border-left: 3px solid $marina-blue;
	@include rem(font-size, 25px);
	color: $port-blue;
	font-style: italic;
	line-height: 1.5;
}

.strike {
	text-decoration: line-through;
	color: $medium-gray;
}

.small-text {
	@include rem(font-size, 12px);
}


// Unordered Lists
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
// <ul>
//   <li>Lorem ipsum dolor</li>
//   <li>Sit amet, consectetur
//     <ul>
//       <li>Lorem ipsum dolor
//         <ul>
//           <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit</li>
//         </ul>
//       </li>
//       <li>Sit amet, consectetur</li>
//       <li>Adipisicing elit, sed</li>
//       <li>Do eiusmod tempor</li>
//       <li>Incididunt ut labore</li>
//       <li>Et dolore magna</li>
//       <li>Aliqua. Ut enim</li>
//     </ul>
//   </li>
//   <li>Adipisicing elit, sed</li>
//   <li>Do eiusmod tempor</li>
//   <li>Incididunt ut labore</li>
//   <li>Et dolore magna</li>
//   <li>Aliqua. Ut enim</li>
// </ul>
//
// Styleguide 3.3

// Ordered Lists
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
// <ol>
//   <li>Lorem ipsum dolor</li>
//   <li>Sit amet, consectetur
//     <ul>
//       <li>Lorem ipsum dolor</li>
//       <li>Sit amet, consectetur</li>
//       <li>Adipisicing elit, sed</li>
//       <li>Do eiusmod tempor</li>
//       <li>Incididunt ut labore</li>
//       <li>Et dolore magna</li>
//       <li>Aliqua. Ut enim</li>
//     </ul>
//   </li>
//   <li>Adipisicing elit, sed</li>
//   <li>Do eiusmod tempor</li>
//   <li>Incididunt ut labore</li>
//   <li>Et dolore magna</li>
//   <li>Aliqua. Ut enim</li>
// </ol>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
//
// Styleguide 3.4
ul, ol {
  margin: 0 0 25px;
  padding-left: 1.1em;

  li {
    @include rem(font-size, 16px);
    letter-spacing: 0.02em;
  }
}

mark {
  background-color: $light-red;
  color: $white;
  padding: 1px 3px;
}
