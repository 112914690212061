.clia-main-nav {
  position: relative;
  z-index: 50;

  > ul {
    display: flex;
    z-index: 50;
    justify-content: flex-end;
    align-items: center;
    margin: 0;

    > li {
        position: relative;
        padding: 0 12px;
        @include rem(font-size, 13px);

        &:hover {

          > a {
              color: $light-red;
              border-bottom: 3px solid $light-red;
            }
        }

        &:not(.js-dropdown-disabled) {
          &:hover {
            .nav-dropdown {
              visibility: visible;
              opacity: 1;
            }
          }
        }

        &:last-child {
          padding: 0 0 0 6px;
        }

        &.search {
          position: static;
        }

        @include from(10) {
          @include rem(font-size, 14px);
        }

        @include from(11) {
          @include rem(font-size, 15px);
          letter-spacing: 1px;
        }

        @include from(12) {
          @include rem(font-size, 17px);
        }

      > a {
          padding: 18px 0;
          color: $dark-blue;
          font-weight: 900;
          text-decoration: none;
          border-bottom: 3px solid transparent;
          @include transition(border-bottom, color);

          @include from(12) {
            padding: 16px 0 18px;
          }

        &.icon-search {
          position: relative;
          border: 0;
          padding: 0;

          &:hover {
            border: 0;
          }

          &.-active {
            color: $light-red;
          }
        }
      }
    }
  }
}

.page--home {

  .clia-main-nav > ul > li {

    > a {
      color: $white;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);

      &.icon-search {

        &.-active {
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }

    }

    &:hover {

      > a {
        color: $white;
      }
    }

    &.search {

      a {

        &:hover {
          color: rgba($white, 0.65);
        }
      }
    }
  }
}
