.sidenav-mobile {

  @include from(8) {
    display: none;
  }
}

.sidenav {
  display: none;
  margin: 0 0 30px;
  border-left: 1px solid $alt-light-blue;

  @include from(8) {
    display: block;
    max-width: 285px;
  }

  li {
    position: relative;
    margin: 0 0 10px;
    list-style: none;
    border: 0;
    @include rem(font-size, 18px);
    line-height: 1.2;

    a,
    span {
      flex-grow: 1;
      display: block;
      padding: 6px 0;
      color: $darkest-gray;
      font-weight: 400;
      text-decoration: none;

      &:hover {
        color: $marina-blue;
      }
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: -18px;
      width: 3px;
      height: 0;
      @include transition(height);
    }

    &.-active {

      > a {
        font-weight: 700;
        color: $dark-blue;
      }

      &::before {
        height: 100%;
        background: $light-red;
      }
    }
  }

  > li {

    > div {
      display: flex;
      justify-content: space-between;
      padding: 0 0 0 25px;
    }

    ul  {
      display: none;
      margin: 10px 0 0;
      padding: 0;
    }

    li {
      margin: 0;

      a {
        @include rem(font-size, 17px);
        color: #636363;
        padding: 10px 15px;
        @include transition(background-color);

        &:hover {
          background-color: $ice-blue;
        }
      }

      &.-active {

        a {
          font-weight: 700;
          color: $dark-blue;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  &.left-nav {

    > li {

      &.-active {

        ul {
          display: block;
        }
      }
    }
  }

  $steps: 6;

  &.quick-links {
    margin-top: 0;
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.1);

    li {
      margin: 0;

      a {

        &:hover {
          background-color: transparent;
        }
      }

      .accordion-trigger {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin-left: 0px;

        &::before {
          display: none;
        }

        &:hover {
          span {

            &:first-of-type,
            &.plus::before {
              color: rgba($white, .6);
            }
          }
        }

        span {
          padding: 0;
          @include transition(color);

          &:first-of-type {
            padding: 14px 22px;
            color: $white;
            @include rem(font-size, 16px);
            font-weight: 900;
          }

          &::after {
            display: none;
          }
        }
      }

      &::before {
        display: none;
      }

      .plus {
        flex-grow: 0;

        &::before {
          content: "\e905";
          display: block;
          width: 45px;
          height: 100%;
          line-height: 52px;
          text-align: center;
          font-family: $icon;
          font-size: 16px;
          font-weight: 900;
          color: $white;
          background-color: rgba($dark-blue, .1);
          @include transition(color);
        }
      }

      &.-active {

        .accordion-trigger {

          .plus {

            &::before {
              content: '\e904';
            }
          }
        }
      }

    }

    > li {

      @for $i from 0 to $steps {
        &:nth-child(#{$steps}n + #{$i + 1}) {
          background-color: darken($marina-blue, $i * 6);
        }
      }

      > div {
        display: block;
        padding: 0;

        > a {
          @include transition(color);
          &:hover {
            color: rgba($white, .6);
          }
        }
      }

      ul {
        margin: 0;
        padding-left: 20px;
        background: $white;

        li {
          margin-right: 20px;
          a {
            display: flex;
            justify-content: space-between;
            padding: 15px 0;
            @include rem(font-size, 15px);
            color: $cerulean-blue;
            font-weight: 600;

            &::after {
              content: "\e903";
              display: block;
              margin-left: 15px;
              font-family: $icon;
              color: $silver-gray;
              font-weight: 400;
              @include transition(color);
            }

            &:hover {

              &::after {
                color: darken($silver-gray, 20%);
              }
            }
          }

          &:not(:last-of-type) {
            a {
              border-bottom: 1px solid $alt-light-blue;
            }
          }
        }
      }
    }
  }
}

.eyebrow {

  &.quick-links-header {
    display: block;
    margin: 10px 0 5px;
    color: $medium-gray;
  }
}
