* {
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

html {
  @include font-smooth;
  height: 100%;
}

html, body {
  -webkit-tap-highlight-color: rgba(0,0,0,0); /* disable webkit tap highlight */
}

body {
  position: relative;
  font-family: $body-text-font;
  @include rem(font-size, 16px);
  color: $body-text-color;
  min-width: 320px;
  font-weight: 600;
  background: $dark-blue;
}

// Horizontal Rules
//
// Markup:
// <hr>
//
// Styleguide 4.1
hr {
	margin: 40px auto;
	border: 0;
	border-bottom: solid 1px $hr-color;
	background-color: transparent;
	clear: both;

	&.small {
		margin: 20px 0 25px;
		border-bottom: 3px solid rgba($hr-color, 0.7);
		width: 50px;
	}
}

// Images
//
// Markup:
// <img src="https://placekitten.com/600/363" alt="Kittens!">
//
// Styleguide 4.2
img {
  max-width: 100%;
  height: auto;
  display: block;
}

// Video Containers
//
// Markup:
// <div class="video-container">
//  <iframe width="560" height="315" src="https://www.youtube.com/embed/56ucT_Hw4bg" frameborder="0" allowfullscreen></iframe>
// </div>
//
// Styleguide 4.3
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  .placeholder,
  embed,
  iframe,
  object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-text {
  margin: 30px 0;
}

.sr-text {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
