// if there is something before the form, give extra space
ul + .sponsorship-form,
div + .sponsorship-form,
section + .sponsorship-form {
	margin-top: 40px;
}

// general styles
.sponsorship-form {
	padding-bottom: 20px;
	h1,h2,h3,h4,h5,h6 {
		color: $light-red;
		width: 100%;
	}

	input[type=tel] {
		width: 100%;
	}

	.sponsorship-check-wrapper {
		margin-bottom: 20px;
		max-width: 100%;
		.checkbox-container {
			.plain {
				width: 100%;
			}
		}
	}

	@include from(6) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		max-width: 100%;
		.input {
			width: 47%;
		}
		.sponsorship-check-wrapper {
			max-width: 100%;
			width: 100%;

			.checkbox-container {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				max-width: 100%;
				width: 100%;

				.plain {
					max-width: 47%;
					width: 47%;
				}
			}
		}
	}

}