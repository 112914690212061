.directory {

  h4 {
    padding: 30px 0 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #e7e8ed;
  }

  .category {
    padding: 30px 0 0;
    margin: 0 -10px;
    @include clearfix-micro;

    .item {
      position: relative;
      display: inline-block;
      float: left;
      margin: 0 10px 50px;
      padding: 0 0 20px;
      text-decoration: none;

      @include to(5) {
        width: calc(50% - 20px);

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:nth-child(2n + 3) {
          clear: left;
        }
      }

      @include between(6, 8) {
        width: calc(33.33333% - 20px);

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(3n + 4) {
          clear: left;
        }
      }

      @include between(9, 11) {
        width: calc(25% - 20px);

        &:nth-child(4n) {
          margin-right: 0;
        }

        &:nth-child(4n + 5) {
          clear: left;
        }
      }

      @include from(12) {
        width: calc(20% - 20px);

        &:nth-child(5n) {
          margin-right: 0;
        }

        &:nth-child(5n + 6) {
          clear: left;
        }
      }

      img {
        width: 100%;
        border: 2px solid #ededf1;
        margin: 0 0 15px;
      }

      p {
        margin: 0;
        color: $port-blue;
        line-height: 1.35;
        font-weight: 900;
      }

      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 40px;
        background-color: $summer-blue;
        @include transition(width 0.5s $te);
      }

      @include from(9) {
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    top: 50px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

.js-added-directory-content {
  position: relative;
  animation-name: fadeInSlideUp;
  animation-duration: 1.5s;
}
