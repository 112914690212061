footer {
	background-color: $dark-blue;
	color: $white;
  flex: none;

	// top section styling
	.top-section {
		padding: 40px 0;

    .inner {

      @include from(6) {
        display: flex;
				// changing this since they are not using stay connected on live site
        // justify-content: space-between;
				justify-content: center;
      }
    }

		.clia-footer-logo {
			display: none;

			@include from(10) {
				display: block;
			}

			img {
				width: 182px;
			}
		}

		.logo-social-side {
			text-align: center;
			min-width: 145px;

      @include from(6) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }

      @include from(10) {
        align-items: center;
      }

			img {
				margin: 0 auto 20px;

        @include from(6) {
          margin: 0;
        }

        @include from(10) {
          margin-right: 20px;
          padding: 10px 20px 10px 0;
          border-right: 1px solid rgba($white, 0.2);
        }
			}

			.social-wrapper {

				a {
					display: inline-block;
					width: 39px;
					height: 39px;
					text-align: center;
					line-height: 39px;
					background-color: rgba($white, 0.3);
					border-radius: 50%;
					color: $white;
					margin: 0 1px 0 2px;
					transition: background-color $ts $te;
					font-size: 0;
					text-decoration: none;
					position: relative;

					&::before {
						@include rem(font-size, 16px);
						line-height: 39px;
						position: absolute;
						left: 0;
						right: 0;
						margin: auto;
					}

					&:hover,
          &:focus {
						background-color: rgba($white, 0.5);
					}

					&.icon-pinterest {

						&::before {
							@include rem(font-size, 22px);
						}
					}

					&.icon-youtube {

						&::before {
							@include rem(font-size, 18px);
						}
					}
				}
			}
		}

		.stay-connected-side {
			padding-top: 20px;
			text-align: center;

      @include from(6) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        padding: 0;
      }

      @include from(10) {
        flex-direction: row;
      }

			label {
				@include rem(font-size, 13px);
				font-weight: 900;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				color: $white;

        @include from(6) {
          padding: 0 0 10px;
        }

        @include from(10) {
          margin-right: 10px;
          padding: 10px 0;
        }

				i {
					vertical-align: sub;
					@include rem(font-size, 18px);
					margin-right: 4px;
				}
			}

			form {
				display: block;
				margin: auto;

				input {
					padding-right: 55px;
					width: 100%;

          @include from(6) {
            width: 350px;
          }
				}

				button {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}

	//homepage affiliate logo styling
	.footer-logos {

		.inner {
			border-top: 1px solid rgba($white, 0.2);
			padding-top: 20px;
			padding-bottom: 20px;

      @include from(6) {
        display: flex;
				flex-direction: row;
				justify-content: center;
      }
		}

		figure {
			padding: 20px;
			text-align: center;

      @include from(6) {
        padding: 5px 20px;
				border-right: 1px solid rgba($white, 0.2);
				max-width: 33%;

				&:last-of-type{
					border-right: none;
				}
      }

      @include from(10) {
        padding: 5px 50px;
      }

			img {
				margin: auto;

        @include from(6) {
          width: 100%;
        }
			}
		}
	}

	//copyright section styling

	.copyright {
		background: url(../images/wavy-wall.png) repeat center center;
		padding: 20px 0;

		.text-side {
			p {
				color: rgba($white, 0.75);
				margin-bottom: 0;
				@include rem(font-size, 13px);
			}
		}

		.link-side {
			ul {
				margin: 0;
				padding: 0;
				li {
					display: inline-block;
					@include rem(font-size, 13px);
					&:not(:last-of-type):after{
						content: '|';
						margin: 0 2px 0 3px;
					}
					a {
						color: rgba($white, 0.75);
					}
				}
			}
		}

		@include from(6){
			.inner {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}
			.text-side {
				max-width: 50%;
			}
			.link-side {
				text-align: right;
				max-width: 50%;
			}
		}

	}

}
