.narrow-content {
	padding: 30px 0;

	.inner {
		max-width: 800px;
	}

	h1,h2,h3,h4,h5,h6,li {
		line-height: 1.4;
	}

	h4,h5,h6 {
		color: $light-red;
	}

	p {
		a {
			font-weight: bold;
		}
	}

	ul,
	ol {
		li {
			margin-bottom: 10px;
			font-weight: normal;
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin-left: 0;
		li {
			padding-left: 1em;
			position: relative;
			&:before{
				content: '\2022';
				position: absolute;
				left: 0;
				top: 0;
				color: $light-blue;
			}
		}
	}

	.item {
		padding: 35px 0 10px;
		position: relative;
		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 50px;
			height: 1px;
			background-color: rgba($black, 0.1);
		}
		&:first-of-type{
			padding-top: 0;
		}
		&:last-of-type{
			&:after{
				display: none;
			}
		}
	}

	@include from(8){
		padding: 60px 0 30px;
	}

}