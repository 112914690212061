.tooltip {
  position: relative;
  z-index: 9999;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  background-color: $dark-blue;
  border-radius: 50%;
  color: $white;
  font-size: 0;
  text-align: center;
  text-indent: 0;
  cursor: pointer;
  transition: background-color $ts $te;

  &:hover,
  &:focus,
  &[aria-describedby] {
    background-color: $marina-blue;
  }

  &::after {
    content: '?';
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 900;
  }
}

.tippy-tooltip.clia-theme {
  background-color: $dark-blue;
  @include rem(font-size, 14px);
}

.tippy-popper[x-placement^=top] .tippy-tooltip.clia-theme .tippy-arrow {
  border-top-color: $dark-blue;
}
