@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icons/icomoon.eot?rl8tkl');
  src:  url('../fonts/icons/icomoon.eot?rl8tkl#iefix') format('embedded-opentype'),
    url('../fonts/icons/icomoon.woff2?rl8tkl') format('woff2'),
    url('../fonts/icons/icomoon.ttf?rl8tkl') format('truetype'),
    url('../fonts/icons/icomoon.woff?rl8tkl') format('woff'),
    url('../fonts/icons/icomoon.svg?rl8tkl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icons: (
  search-close: '\e91f',
  star-full: '\e913',
  star-empty: '\e918',
  stars-1: '\e91b',
  stars-2: '\e91c',
  stars-3: '\e91d',
  stars-4: '\e91e',
  stars-5: '\e923',
  user: '\e91a',
  quote: '\e919',
  locked: '\e915',
  link: '\e917',
  phone: '\e916',
  radio: '\e914',
  radio-checked: '\e910',
  checkbox: '\e911',
  checkbox-checked: '\e912',
  play: '\e909',
  arrow-left: '\e90a',
  arrow-down: '\e90b',
  arrow-up: '\e90c',
  search: '\e90d',
  arrow-right: '\e903',
  linkedin: '\e900',
  twitter: '\e901',
  facebook: '\e902',
  diamond: '\e90f',
  menu: '\e90e',
  minus: '\e904',
  plus: '\e905',
  close: '\e906',
  chevron-down: '\e907',
  mail: '\e908',
  notification: '\e920',
  warning: '\e920',
  shopping-cart: '\e921',
  print: '\e922',
  pdf: '\e926',
  check-circle: '\e927',
  files: '\e928',
  pencil: '\e92a',
  delete: '\e929',
  chat: '\e92b',
  youtube: '\e92c',
  pinterest: '\e92d',
  instagram: '\e92e'
);

[class^='icon-'],
[class*=' icon-'] {

  &::before {
    @include icomoon;
  }
}

@each $icon, $content in $icons {
  .icon-#{$icon} {

    &::before {
      content: '#{$content}';
    }
  }
}


// Site Icons
//
// Icons:
// star-full            : icon-star-full
// star-empty           : icon-star-empty
// stars-1              : icon-stars-1
// stars-2              : icon-stars-2
// stars-3              : icon-stars-3
// stars-4              : icon-stars-4
// stars-5              : icon-stars-5
// user                 : icon-user
// quote                : icon-quote
// locked               : icon-locked
// link                 : icon-link
// phone                : icon-phone
// radio                : icon-radio
// radio-checked        : icon-radio-checked
// checkbox             : icon-checkbox
// checkbox-checked     : icon-checkbox-checked
// play                 : icon-play
// arrow-left           : icon-arrow-left
// arrow-down           : icon-arrow-down
// arrow-up             : icon-arrow-up
// arrow-right          : icon-arrow-right
// search               : icon-search
// diamond              : icon-diamond
// menu                 : icon-menu
// minus                : icon-minus
// plus                 : icon-plus
// close                : icon-close
// chevron-down         : icon-chevron-down
// mail                 : icon-mail
// search-close         : icon-search-close
// warning              : icon-warning
// notification         : icon-notification
// shopping-cart        : icon-shopping-cart
// Print                : icon-print
// pdf                  : icon-pdf
// check-circle         : icon-check-circle
// files                : icon-files
// pencil               : icon-pencil
// delete               : icon-delete
// chat                 : icon-chat
// youtube              : icon-youtube
// pinterest            : icon-pinterest
// instagram            : icon-instagram
//
// Styleguide 2.1

// Social Media Icons
//
// Icons:
// linkedin             : icon-linkedin
// twitter              : icon-twitter
// facebook             : icon-facebook
//
// Styleguide 2.2
