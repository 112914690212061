.certifactions-guide {
  position: relative;
  background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

  .inner {
    max-width: 1200px;
    margin: auto;
    padding: 40px 20px;

    @include from(8) {
      padding: 60px 40px;
    }
  }

  h2 {
    margin-bottom: 40px;
    color: $white;
    text-align: center;
  }
}

.certifications-guide-cards {

  @include from(8) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -18px;

    @include from(12) {
      flex-wrap: nowrap;
    }
  }

}

.certifications-guide-card {
  margin-bottom: 50px;
  background-color: $white;
  border-top: 3px solid $port-blue;
  text-align: center;
  box-shadow: 0px 11px 16px 2px rgba(0, 0, 0, .25);

  @include from(8) {
    width: calc(50% - 36px);
    margin: 0 18px 40px;
  }

  @include from(12) {
    width: calc(25% - 36px);
  }

  &-inner {
    padding: 30px;
  }

  h3 {
    color: $port-blue;

    &::before {
      content: '';
      display: block;
      margin: 0 0 25px;
      font-family: $icon;
      @include rem(font-size, 56px);
      color: $marina-blue;
    }

    &.-students {

      &::before {
        content: '\e91a';
      }
    }

    &.-new {

      &::before {
        content: '\e91a';
      }
    }

    &.-expert {

      &::before {
        content: '\e91a';
      }
    }

    &.-executive {

      &::before {
        content: '\e91a';
      }
    }
  }

  p {

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.certifications-legend {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -6px;
    left: calc(50% - 3px);
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #f1f3f6 transparent;
  }

  &-row {
    display: flex;
    align-items: center;
    padding: 18px 20px;
    color: $darkest-gray;
    text-decoration: none;

    &:nth-child(odd) {
      background-color: $alt-light-gray;
    }
  }

  &-acronym {
    flex: 0 0 auto;
    width: 80px;
    margin-right: 15px;
    border-right: 2px solid #dcdde5;
    text-align: left;
    @include rem(font-size, 30px);
    line-height: 1;
    font-weight: 900;
    color: $marina-blue;
  }

  &-text {
    flex: 1 1 auto;
    text-align: left;
  }

  &-coming-soon {
    padding: 18px 20px;
  }

  p {
    margin: 0;
    @include rem(font-size, 14px);
    line-height: 1.25;
  }
}
