.press-contact{
	background-color: $light-blue-gray;
	padding: 40px 0 20px;

	.top-section {
		border-bottom: 2px solid rgba($black, 0.1);
		margin-bottom: 20px;
	}

	.tabbed-content {

		.tab {

			.item {
	      margin-bottom: 20px;

	      @include from(8) {
	        width: 50%;
	        padding: 0 20px 0 0;
	      }

	      h1,h2,h3,h4,h5,h6 {
	        margin-bottom: 10px;
	        color: $light-red;
	      }

	      p {
	        @include rem(font-size, 15px);

	        a {
	          font-weight: bold;
	        }
	      }
	    }
		}
	}

	@include from(8){
		.top-section {
			.tab-list {
				justify-content: flex-start;
				li:first-of-type{
					margin-left: 0;
				}
			}
		}
	}

	@include from(9){
		padding: 60px 0 20px;

		h2 {
			margin-bottom: 0;
			padding-right: 10px;
		}

		.tabbed-content {
			.tab {
		      flex-direction: row;
		      justify-content: space-between;
		      flex-wrap: wrap;
		      width: 100%;
		      &.current {
		      	display: flex;
		      }
			}
		}

		.top-section {
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			align-items: baseline;
			-ms-flex: auto;
			margin-bottom: 40px;
			.tab-list {
				justify-content: flex-end;
			}
		}
	}
}
