.image-component {
	padding: 30px 0;
	overflow: hidden;

	// if next to each other less padding on top
	+ .image-component {
		padding: 0 0 30px;
	}

	.image-side {
		display: none;
		overflow: hidden;

		&.compat-object-fit {
			background-size: cover;
			background-position: center center;

			img {
				opacity: 0;
			}
		}
	}

	.text-side {
		background-color: $white;
		z-index: +1;
		padding: 40px 20px;
		position: relative;
		.eyebrow {
			color: $light-red;
		}
		.btn, button {
			margin: 5px 10px 5px 0;
			flex: 0 0 100%;
		}

	}

	&.-left{
		.image-side {
			order: 1;
		}
		.text-side {
			order: 2;
			box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.05);
		}
	}

	&.-right{
		.image-side {
			order: 2;
		}
		.text-side {
			order: 1;
			box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.05);
		}
	}

	@include from(8) {
		padding: 60px 0;

		+ .image-component {
			padding: 0 0 60px;
		}

		.inner {
			display: flex;
			flex-direction: row;
			//align-items: center;
		}

		.image-side,
		.text-side {
			width: calc(50% + 60px);
		}

		.image-side {
			display: block;
			height: auto !important; // need this here so image doesn't disappear during resize (overrides inline css from polyfill)

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50%;
			}
		}
		.text-side {
			margin: 40px 0;
			padding: 40px;
		}

		&.-left {
			.image-side {
				margin-right: -60px;
			}
			.text-side {
				margin-left: -60px;
			}
		}
		&.-right {
			.image-side {
				margin-left: -60px;
			}
			.text-side {
				margin-right: -60px;
			}
		}
	}

	@include from(10){
		.text-side {
			padding: 60px;
		}
	}

}
