.two-up-component {
	padding: 40px 0;
	h1,h2,h3,h4,h5,h6,li {
		line-height: 1.3;
	}

	.eyebrow {
		color: $light-red;
	}

	.left-side,
	.right-side {
		position: relative;
		padding: 20px 0;
		.standard-callout {
			&:before{
				content: '';
				width: 50px;
				height: 3px;
				background-color: $light-red;
				position: absolute;
				top: 0px;
				left: 0;
			}
			&:empty:before{
				display: none;
			}
		}
	}

	.left-side {
		margin-bottom: 20px;
	}

	&.-blue {
		background-color: $alt-dark-blue;

		.eyebrow {
			color: #d4d9e4;
		}

		.left-side,
		.right-side {

			.standard-callout {
				&:before{
					background-color: #d4d9e4;
				}
			}
		}

		h2,
		p,
		.after-arrow {
			color: $white;
		}

		.after-arrow {

			&::after {
				color: #d4d9e4;
			}
		}
	}

	&.-image-split {
		padding: 0;
		.inner {
			padding: 0;
		}

		.left-side {
			background-position: center right;
			background-size: cover;
			background-repeat: no-repeat;
			padding: 40px 20px;
			margin-bottom: 0;
			.left-content-restricter{
				h1,h2,h3,h4,h5,h6,p,li,a:not(.btn){
					color: $white;
				}
				ul {
					list-style: none;
					margin: 0 0 20px;
					padding: 0;
					li {
						a {
							display: block;
							padding: 20px 0;
							border-top: 1px solid rgba($white, 0.25);
							@include rem(font-size, 13px);
							text-transform: uppercase;
							font-weight: 900;
							transition: padding $ts $te;
							&:before{
								content: "\e903";
								font-family: $icon;
								@include rem(font-size, 10px);
								margin-right: 10px;
							}
							&:hover{
								padding-left: 10px;
								text-decoration: none;
							}
						}
						&:last-of-type{
							a {
								border-bottom: 1px solid rgba($white, 0.25);
							}
						}
					}
				}
			}
		}
		.right-side {
			display: none;
			background-position: center left;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

	@include from(8){
		padding: 60px 0;

		.inner {
			display: flex;
			flex-direction: row;
		}

		.left-side,
		.right-side {
			width: 50%;
			padding: 40px;
			.standard-callout {
				&:before{
					top: 10px;
					left: 40px;
				}
			}
		}

		.left-side {
			border-right: 1px solid rgba($dark-blue, 0.1);
			margin-bottom: 0;
		}

		&.-blue {

			.left-side {
				border-right: 1px solid #516993;
			}
		}

		&.-image-split {
			.left-side,
			.right-side {
				padding: 60px 0;
			}
			.left-side {
				border: none;
				width: 100%;
				.left-content-restricter {
					padding: 0 40px;
				}
			}
		}

	}

	@include from(10){

		.left-side,
		.right-side {
			padding: 60px 60px 20px;
			.standard-callout{
				&:before{
					top: 20px;
					left: 60px;
				}
			}
		}

		&.-image-split {
			.inner {
				max-width: 100%;
			}
			.left-side,
			.right-side {
				padding: 80px 0;
				width: 50%;
			}
			.left-side {
				.left-content-restricter {
					max-width: 600px;
					margin-left: auto;
					padding: 0 20% 0 20px;
				}
			}
			.right-side {
				display: block;
			}
		}

	}

}
