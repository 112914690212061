.main-search-dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 60;
  top: 100%;
  right: 0;
  width: 100%;
  padding: 16px;
  background: rgba($dark-blue, 0.8);
  @include transition(opacity .5s);

  &.-active {
    visibility: visible;
    opacity: 1;
  }

  .search-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    border-top: 1px solid $silver-gray;
    border-bottom: 1px solid $silver-gray;
    border-left: 1px solid $silver-gray;
    background: $white;

    .icon-cross {
      position: relative;
      padding: 5px 10px;
      font-size: 0;
      color: transparent;

      &:hover {

        &::before {
          color: $light-red-hover;
        }
      }

      &::before {
        display: block;
        font-size: 20px;
        color: $light-gray;
        @include transition(color);
      }

      &::after {
        content: '';
        position: absolute;
        top: 0px;
        right: 0;
        display: block;
        width: 1px;
        height: 100%;
        background: $silver-gray;
      }
    }

    input[type='search'] {
      flex: 1 1 0;
      background: $white;
      padding: 5px 5px 5px 10px;
      border: 0;
      border-radius: 0;
      margin: 5px;

    }

    button {
      flex: none;
      height: 42px;
      padding: 12px 8px;
      border-radius: 0;
      display: flex;
      align-items: center;

      @include icon(arrow-right) {
        flex: none;
      }

      &:hover,
      &:focus {
        background: $light-red-hover;
        border-color: $light-red-hover;
        color: $white;
      }
    }

    .js-main-search-close {
      flex: none;
      border-right: 1px solid $light-gray;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include icon(search-close) {
        display: inline-block;
        flex: none;
        color: $light-gray;
        @include rem(font-size, 12px);
        padding: 0 10px;
        @include transition(color);
      }

      &:hover,
      &:focus {
        text-decoration: none;

        &::before {
          color: $dark-blue;
        }
      }
    }
  }
}
