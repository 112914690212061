.nav-dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 100;
  width: auto;
  margin: 0;
  padding: 10px;
  background: rgba($white, 0.9);
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3);
  @include transition(opacity .35s);

  @include from(10) {
    padding: 15px;
  }

  @include from(12) {
    padding: 20px;
  }

  li {
    white-space: nowrap;

    &:not(:last-child) {
      margin: 0 0 10px;

      @include from(12) {
        margin: 0 0 20px;
      }

    }

    a {
      @include rem(font-size, 13px);
      color: $dark-blue;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        color: $cerulean-blue;
      }

      @include from(12) {
        @include rem(font-size, 15px);
      }
    }
  }
}
