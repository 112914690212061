// sass-lint:disable-all
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-arrow {
  position: absolute;
  z-index: 1;
  top: calc(50% - 23px);
  left: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $white;
  border-color: $port-blue;
  color: $port-blue;
  font-size: 0;
  border-radius: 50%;
  text-align: center;

  @include from(13) {
    width: 47px;
    height: 47px;
    left: -40px;
  }

  &:hover,
  &:focus {
    background-color: $port-blue;

    &::after {
      color: $white;
    }
  }

  &::after {
    display: block;
    font-family: $icon;
    font-size: 14px;
    color: $port-blue;
  }

  &.slick-prev {

    &::after {
      content: '\e90a';
    }
  }

  &.slick-next {
    right: -20px;
    left: auto;

    @include from(13) {
      right: -40px;
    }

    &::after {
      content: '\e903';
    }
  }
}

.slick-dots {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 7px;

    button {
      display: block;
      width: 8px;
      height: 8px;
      padding: 0;
      font-size: 0;
      background-color: $white;
      border-radius: 50%;
      border-color: $light-gray;
    }

    &.slick-active {

      button {
        background-color: $light-gray;
      }
    }
  }

}
