.certifications-slider-inner {
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 30px;

  @include from(8) {
    margin: 40px auto;
    padding: 0 40px;
  }

  @include from(12) {
    margin: 70px auto;
    padding: 0 40px;
  }
}

.certifications-slider {
  position: relative;

  &-item {
    padding: 0 40px;
    text-align: center;
  }

  // only way i can figure out how to get the vr's to work with the way slick slider clones and adds .slick-active...
  .slick-active {

    .certifications-slider-item {

      @include from(7) {
        border-right: 2px solid #ecedf1;
      }
    }

  + .slick-active {

    .certifications-slider-item {

      @include from(7) {
        border-right: 0;
      }

      @include from(10) {
        border-right: 2px solid #ecedf1;
      }
    }

    + .slick-active {

        .certifications-slider-item {

          @include from(10) {
            border-right: 0;
          }
        }

      ~ .slick-active {

          .certifications-slider-item {

            @include from(13) {
              border-left: 2px solid #ecedf1;
            }
          }
        }
      }
    }
  }

  .course-acronym {
    margin: 0 0 30px;
    @include rem(font-size, 40px);
    font-weight: 900;
    color: $marina-blue;

    a {
      color: $marina-blue;
      text-decoration: none;

      &:hover {
        color: $port-blue;
      }
    }


    &::after {
      content: '';
      display: block;
      margin: 0 auto;
      width: 47px;
      height: 4px;
      background-color: $marina-blue;
      border-radius: 3px;
    }
  }

  h3 {
    @include rem(font-size, 20px);
    font-weight: 900;

    a {
      color: $port-blue;
    }
  }
}
