.breadcrumb-wrapper {
	padding: 10px 0 15px;
	background-color: rgba($black, 0.2);
	//global for both lists
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			display: inline-block;
			color: $white;
			font-weight: 900;
			@include rem(font-size, 12px);
			a {
				font-weight: 500;
				color: $white;
			}
		}
	}


	.breadcrumbs {
		li {
			a {
				margin-right: 5px;
				&:after{
					content: '/';
					margin-left: 7px;
					display: inline-block;
				}
			}
		}
	}

	.social{
		display: none;
		li {
			span {
				padding-right: 7px;
			}
			a {
				text-align: center;
				padding: 0 2px 0 7px;
				border-left: 1px solid rgba($white, 0.25);

				&:hover{
					text-decoration: none;
					color: rgba($white, 0.7);
				}
			}
		}
	}

	@include from(5) {
		.inner {
			display: flex;
			justify-content: space-between;
		}
		.social {
			display: block;
		}
	}

}