.clia-utility-nav {
  height: 37px;
  list-style: none;

  @include from(12) {
    height: 47px;
  }

  > ul {
      display: flex;
      z-index: 70;
      justify-content: flex-end;
      align-items: center;
      list-style: none;

    > li {
      position: relative;
      margin: 0 12px;
      @include rem(font-size, 12px);
      font-weight: 400;
      border-bottom: 3px solid transparent;

      &:last-child {
        margin: 0 0 0 12px;
      }

      .nav-dropdown {
        min-width: 125px;
        top: calc(100% + 3px);
      }

      @include from(12) {
        @include rem(font-size, 13px);
        letter-spacing: 1px;
      }

      > a {
        padding-bottom: 5px;
        color: $medium-gray;
        text-decoration: none;

        &::before {
          margin-right: 5px;
        }

        &:hover {
          color: $light-red;
        }
      }

      &.username {
        font-weight: 700;

        > a {
          color: $black;

          &::after {
            color: $medium-gray;
          }
        }

        .nav-dropdown {
          right: 0;
        }
      }

      &.language,
      &.username {
        > a {
          &::after {
            content: "\e907";
            position: relative;
            top: 2px;
            margin-left: 1px;
            font-family: $icon
          }
        }

        &:hover {
          border-bottom-color: $dark-blue;
          > a {
            color: $light-red;
          }
        }
      }

      &.cart {
        position: relative;
        padding-top: 3px;

        @include from(12) {
          letter-spacing: 0px;
        }

        a {
          display: block;
          font-size: 0;

          &::before {
            content: '\e921';
            margin: 0;
            font-family: $icon;
            @include rem(font-size, 20px);
            color: #b2b2b2;
          }
        }

        .cart-total {
          position: relative;
          display: inline-block;
          vertical-align: top;
          top: 2px;
          margin-left: 4px;
          padding: 2px 8px;
          background-color: $port-blue;
          @include rem(font-size, 10px);
          border-radius: 12px;
          color: $white;
          font-weight: 900;

          @include from(12) {
            margin-left: 8px;
          }
        }
      }

      &:hover {

        .nav-dropdown {
          visibility: visible;
          opacity: 1;

          li {

            &:not(:last-child) {
              margin: 0 0 5px;

              @include from(12) {
                margin: 0 0 10px;
              }

            }
          }
        }
      }

      .btn {
        padding: 0 18px;
        height: 34px;
        line-height: 34px;
        @include rem(font-size, 11px);
        font-weight: 900;
        color: $white;
        border-radius: 0;
        border: 0;

        &:hover,
        &:focus {
          background: $light-red-hover;
          color: $white;
          outline-style: none;

          &::before {
            opacity: 1;
          }
        }

        @include from(12) {
          height: 44px;
          line-height: 44px;
          @include rem(font-size, 12px);
        }
      }

      .icon-user {

        &::before {
          position: relative;
          top: 2px;
          font-size: 13px;
          opacity: .5;
          @include transition(opacity);
        }
      }
    }
  }
}


.page--home {

  .clia-utility-nav > ul > li {

    > a {
      color: $white;

      &:hover {
        color: rgba($white, 0.65);
      }
    }

    &.language {

      &:hover {

        > a {
          color: rgba($white, 0.65);
        }
      }
    }

    .btn {

      &:hover {
        color: $white;
      }
    }
  }
}
