.info-box {
  margin-bottom: 30px;
  padding: 30px;
  background-color: $alt-light-gray;

  @include from(12) {
    max-width: 275px;
  }

  h4 {
    @include rem(font-size, 12px);
    color: $dove-gray;
    text-transform: uppercase;
    letter-spacing: .12em;
  }

  p {
    margin-bottom: 8px;
    color: $black;

    &:last-of-type {
      margin-bottom: 25px;
    }
  }
}
