.tab-list {
  display: none;
  margin: 0 0 -1px;
  padding: 0;
  list-style: none;

  @include from(8) {
    display: flex;
    -ms-flex: 1 0 auto;
    flex-wrap: no-wrap;
    flex-direction: row;
    justify-content: flex-end;
  }

  li {
    margin: 0 10px;

    @include from(11) {
      margin: 0 15px;
    }

    &.current{

      a {
        color: $light-red;
        font-weight: 900;
        border-bottom-color: $light-red;
      }
    }

    a {
      display: block;
      padding: 0 0 15px;
      color: $dark-blue;
      border-bottom: 3px solid transparent;
      transition: border $ts $te;

      &:hover{
        text-decoration: none;
        border-bottom-color: $dark-blue;
      }
    }
  }
}

.tab-list-mobile {
  display: block;
  margin-bottom: 20px;

  @include from(8) {
    display: none;
  }
}

.tabbed-content {

  .tab {
    display: none;

    &.current {
      display: block;
    }    
  }
}
