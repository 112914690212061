// Base structure
html {
  // Set fallback font family to sans-serif.
  font-family: sans-serif;
  // Prevent iOS text size adjust on orientation change without disabling user zoom.
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
}

// HTML5 display definitions.
article,
aside,
details,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
}

// Address styling not present in IE 8/9, Safari 5, and Chrome.
abbr[title] {
  border-bottom: 1px dotted;
}

// Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome.
b,
strong {
  font-weight: bold;
}

// Address styling not present in Safari 5 and Chrome.
dfn {
  font-style: italic;
}

// Address differences between Firefox and other browsers.
hr {
  box-sizing: content-box;
  height: 0;
}

// Address styling not present in IE 8/9.
mark {
  background: #ff0;
  color: #000;
}

// Correct font family set oddly in Safari 5 and Chrome.
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

// Improve readability of pre-formatted text in all browsers.
pre {
  white-space: pre-wrap;
}

// Links
// Improves readability when focused and also mouse hovered in all browsers.
// people.opera.com/patrickl/experiments/keyboard/test
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;

  &:active,
  &:hover {
    outline: 0;
  }

  &:focus {
    outline: thin dotted;
  }
}

// Lists
// Corrects list images handled incorrectly in IE7.
nav ol,
nav ul {
  list-style: none;
  list-style-image: none;
}

// Quotes
// Sets consistent quote types.
q {
  quotes: '\201C' '\201D' '\2018' '\2019';
}

// Address inconsistent and variable font size in all browsers.
small {
  font-size: 80%;
}

// Prevent `sub` and `sup` affecting `line-height` in all browsers.
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

// Pre-formatted text
// Improves readability and allows line-wrapping
pre {
  overflow: auto;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

audio:not([controls]) {
  display: none;
}

// Ensures content is hidden from all presentations, including screenreaders.
[hidden] {
  display: none;
}

// Responsive images & embedded content
img {
  border: 0; // Removes border when inside `a` element in IE 8/9.
  max-width: 100%; // Responsive images
  height: auto;

  // Improve IE's resizing of images: css-tricks.com/ie-fix-bicubic-scaling-for-images
  .ie8 & {
    width: auto;
  }
}

// Images in figure elements.
figure > img {
  display: block;
}

// Override max-width 100% for map displays
#getlocations_map_canvas,
.gmap,
.openlayers-map,
.view-gmap {

  img {
    max-width: none;
  }
}

figure {
  margin: 0;
}

// Corrects overflow displayed oddly in IE9.
svg:not(:root) {
  overflow: hidden;
}

// Forms

// Define consistent border, margin, and padding.
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

// 1. Correct `color` not being inherited in IE 8/9.
// 2. Remove padding so people aren't caught out if they zero out fieldsets.
legend {
  border: 0;
  padding: 0;
}

// 1. Correct font family not being inherited in all browsers.
// 2. Correct font size not being inherited in all browsers.
// 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

// Address Firefox 4+ setting `line-height` on `input` using `!important` in
// the UA stylesheet.
button,
input {
  line-height: normal;
}

// Address inconsistent `text-transform` inheritance for `button` and `select`.
// All other form control elements do not inherit `text-transform` values.
// Correct `button` style inheritance in Chrome, Safari 5+, and IE 8+.
// Correct `select` style inheritance in Firefox 4+ and Opera.
button,
select {
  text-transform: none;
}

// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
//    and `video` controls.
// 2. Correct inability to style clickable `input` types in iOS.
// 3. Improve usability and consistency of cursor style between image-type
//    `input` and others.
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

// Re-set default cursor for disabled elements.
button[disabled],
html input[disabled] {
  cursor: default;
}

// 1. Address box sizing set to `content-box` in IE 8/9.
// 2. Remove excess padding in IE 8/9.
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

// 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
// 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
//    (include `-moz` to future-proof).
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

// Remove inner padding and search cancel button in Safari 5 and Chrome
// on OS X.
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

// Remove inner padding and border in Firefox 4+.
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// 1. Remove default vertical scrollbar in IE 8/9.
// 2. Improve readability and alignment in all browsers.
textarea {
  overflow: auto;
  vertical-align: top;
}

// Tables
// Remove most spacing between table cells.
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// Print styles
// Inlined to avoid extra HTTP connection.
@media print {
  * {
    background: transparent !important;
    color: black !important;
    filter: none !important;
    // Black prints faster: sanbeiji.com/archives/953
    -ms-filter: none !important;
  }

  a {
    text-decoration: underline;

    &:visited {
      text-decoration: underline;
    }

    &[href]:after {
      content: " (" attr(href) ")";
    }
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  // Do not show javascript and internal links.
  a {
    &[href^="javascript:"]:after,
    &[href^="#"]:after {
      content: "";
    }
  }

  // Printing Tables: css-discuss.incutio.com/wiki/Printing_Tables
  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  @page {
    margin: 0.5cm;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
