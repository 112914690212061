.upcoming-events-component {
	padding: 30px 0 40px;

	&.-courses {
		padding: 0;

		.inner {
			padding: 0;
		}
	}

	.top-section {
		margin-bottom: 20px;
		h2, a {
			display: inline-block;
			vertical-align: middle;
			margin: 10px 0;
		}
		h2 {
			margin-right: 10px;
		}
	}

	.events-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.event-item {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			background-color: $alt-light-gray;
			padding: 20px;
			margin: 0 0 20px;
			.date-side {
				width: 100%;
				padding: 10px 10px 20px;
				text-align: center;
				margin-bottom: 10px;
				border-bottom: 1px solid rgba($black, 0.1);
				month, year, day {
					display: block;
					font-weight: 900;
					line-height: 1;
				}
				month, day {
					color: $light-blue;
				}
				month {
					@include rem(font-size, 12px);
					text-transform: uppercase;
					margin-bottom: 4px;
				}
				day {
					@include rem(font-size, 30px);
					margin-bottom: 8px;
				}
				year {
					color: $medium-gray;
					@include rem(font-size, 12px);
				}
			}
			.event-side {
				width: 100%;
				padding: 10px;
				h3 {
					color: $dark-blue;
					margin-bottom: 10px;
					line-height: 1.2;
					a {
						color: $dark-blue;
					}
				}
				p {
					@include rem(font-size, 14px);
					line-height: 1.3;
					margin-bottom: 0;
					span {
						&:after{
							content: '|';
							display: inline-block;
							margin: 0 1px 0 4px;
						}
						&:last-of-type:after{
							display: none;
						}
					}
				}
			}
		}
	}

	@include from(4) {
		.events-wrapper {
			.event-item {
				.date-side {
					width: 30%;
					border-right: 1px solid rgba($black, 0.1);
					text-align: center;
					margin-bottom: 0;
					border-bottom: none;
				}
				.event-side {
					width: 70%;
					padding: 10px 0px 5px 15px;
				}
			}
		}

		&.-courses {
			.events-wrapper {
				.event-item {
					.event-side {
						width: 100%;
						padding: 0;
					}
				}
			}
		}
	}

	@include from(7){
		padding: 60px 0;
		.events-wrapper {

			.event-item {
				padding: 25px 20px;
				width: calc(50% - 10px);
				margin: 0 20px 20px 0;
				&:nth-of-type(2){
					margin: 0 0 20px;
				}
				.date-side {
				}
				.event-side {
					padding-left: 20px;
				}
			}
		}

	}

	@include from(10){

		.events-wrapper {

			.event-item {
				width: calc(33% - 11px);
				margin: 0;
				&:nth-of-type(2){
					margin: 0 20px 0;
				}
				.date-side {
				}
				.event-side {
				}
			}
		}

	}

}
