.filter-group {
	border-top: 1px solid rgba($black, 0.1);
	padding: 20px 0 10px;
	margin-top: 20px;

	@include from(8) {
	  display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	@include from(10) {
	  margin-bottom: 0;
	}

	h6 {
		color: $dove-gray;
		text-transform: uppercase;
		@include rem(font-size, 12px);
		margin: 0 5px 0 0;

		@include from(8) {
		  margin-bottom: 0;
			margin-right: 10px;
		}
	}

	.filter-list {
		margin: 0;
		padding: 0;
		list-style: none;
		width: 100%;
		margin-bottom: 20px;

		li {
			display: inline-block;
			margin: 10px 5px 0 0;

			@include from(8) {
			  margin-top: 0;
			}

			a {
				@include rem(font-size, 12px);
				display: inline-block;
				border-radius: $border-radius;
				color: $dark-blue;
				font-weight: 900;
				background-color: $light-blue-gray;
				border: 1px solid #d9e1e5;
				padding: 8px 35px 8px 10px;
				position: relative;
				transition: background-color $ts $te;
				span {
					background-color: #eff3f5;
					color: rgba($black, 0.3);
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					width: 25px;
					text-align: center;
					line-height: 35px;
					@include rem(font-size, 14px);
					transition: background-color $ts $te;
				}
				&:hover{
					text-decoration: none;
					background-color: darken($light-blue-gray, 5%);
					span {
						background-color: darken(#eff3f5, 5%);
					}
				}

			}
			&:last-child {
				margin-right: 15px;
			}
		}
	}

	.reset {
		display: block;
		margin-top: 10px;
		@include rem(font-size, 14px);
		font-weight: 900;

		@include from(8) {
		  margin-top: 0;
			margin-left: auto;
		}
	}

	@include from(6){
		
		.filter-list {
			width: auto;
			margin-bottom: 0;
			h6 {
				display: inline-block;
				margin-bottom: 0px;
			}
		}
	}

}
