// Links
//
// :hover       - Hover state
// :focus       - Focus state
// :active      - Active state
// .after-arrow - Arrow After
// .small       - Small Link
//
// Markup:
// This is a link: <a class="{{modifier_class}}" href="#">Click here to learn more!</a>
//
// Styleguide 5.1
a {
  display: inline-block;
	color: $link-color;
	text-decoration: none;
	transition: color $ts $te;
	cursor: pointer;

	&:focus {
	  outline: 0;
  }

	&:hover,
  &:focus,
  &:active {
		text-decoration: underline;
	}

  &:disabled,
  &[disabled] {
    opacity: .5;
    pointer-events: none;
    cursor: default;
  }

	&.after-arrow {

	  &::after{
	    content: "\e903";
	    font-family: $icon;
	    @include rem(font-size, 13px);
	    margin-left: 5px;
	    transition: margin $ts $te;
	  }

	  &:hover{
	  	text-decoration: none;

	  	&::after{
	  		margin-left: 8px;
	  	}
	  }
	}

	&.small {
		@include rem(font-size, 14px);
	}

  &.-print {

    &::before {
      content: '\e922';
      font-family: $icon;
      display: inline-block;
      margin-right: 5px;
    }
  }

  &.-pdf {
    @include rem(font-size, 13px);
    color: $port-blue;

    @include icon(pdf) {
      display: inline-block;
      margin-right: 8px;
      color: #b2b2b2;
    };
  }

  &.link-arrow {

    &::after {
      content: "\e903";
      position: relative;
      left: 3px;
      font-family: $icon;
      font-size: 10px;
      color: rgba($white, 0.6);
      @include transition(left);
    }

    span {
      display: inline-block;
      border-bottom: 1px solid $white;
      @include transition(color, left, border-color);
    }

    &:hover,
    &:focus {

      span {
        color: rgba($white, 0.6);
        border-color: rgba($white, 0.6);
      }

      &::after {
        left: 6px;
      }
    }
  }

  &.-add-item {
    @include rem(font-size, 14px);
    color: $port-blue;
    text-decoration: none;
    @include icon(plus);

    &:hover,
    &:focus {
      color: $light-red;
    }
  }

}

.chat-help {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  right: 20px;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: $marina-blue;
  transition: background-color $ts $te;
  @include icon(chat) {
    color: $white;
    @include rem(font-size, 24px);
  };

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $light-red;
  }
}
