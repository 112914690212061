/*
Slicer Breakpoints
https://github.com/lolmaus/breakpoint-slicer

Breakpoint:    0      100px   200px   320px   400px   500px   600px   700px       1600px
               |───────┼───────┼───────┼───────┼───────┼───────┼───────┼──── ... ───┼────────>
Slice #:               1       2       3       4       5       6       7           16
*/
$slicer-breakpoints: 100px 200px 320px 400px 500px 620px 700px 800px 900px 1000px 1100px 1200px 1300px 1400px 1500px 1600px;
$slicer-anti-overlap-corrections: -1px;

// sitewide vars
$site-max-width: 1200px;
$nav-break: 9;



$arial:  Arial, 'Helvetica Neue', Helvetica, sans-serif;
$lato: 'lato', sans-serif;
$icon: 'icomoon';

// type variables
$body-text-color: $darkest-gray;
$body-text-font: $lato;
$header-font: $lato;
$header-color: $dark-blue;
$link-color: $light-blue;

$hr-color: $silver-gray;


// radius
$border-radius: 4px;


// transitions
$ts: 0.25s; // transition speed
$te: ease; // transition ease
