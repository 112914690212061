// labels
$label-padding: 10px 0;

// input [text, email, password, search]
$input-width: 100%;
$input-margin: 0 0 15px;
$input-padding: 10px;
$input-font-size: 14px;
$input-color: #666;
$input-border-top: 1px solid $silver-gray;
$input-border-right: 1px solid $silver-gray;
$input-border-bottom: 1px solid $silver-gray;
$input-border-left: 1px solid $silver-gray;
$input-border-radius: $border-radius;
$input-box-shadow: 0 0 0 0;
$input-background-color: $light-blue-gray;
$input-focus-bg: darken($light-blue-gray, 5%);
$input-focus-color: $darkest-gray;
$input-focus-placeholder: $darkest-gray;
$input-focus-placeholder-opacity: 1;

// textarea
$textarea-minheight: 100px;

// select
$select-bg-color: $light-blue-gray;
$select-padding: 8px;
$select-padding-right: 35px; // might need to be adjusted so text doesn't overlap arrow
$select-font-size: $input-font-size;
$select-arrow-img: url(../images/select-arrow-down.svg);
$select-arrow-offset: 4px; // distance that arrow is from right side of select
$select-border-radius: $input-border-radius;

// checks and radios
$ch-rad-label-padding-bottom: 5px;
$ch-rad-label-margin-bottom: 0px;
$ch-rad-label-padding-left: 30px;
$ch-rad-label-text-indent: -($ch-rad-label-padding-left); // might need to be adjusted based on $checks-width-height

$checks-width: 22px;
$checks-height: 20px;
$checks-margin: -3px 8px 0 0; // space around span

$radios-width: 22px;
$radios-height: 20px;
$radios-margin: -3px 8px 0 0; // space around span

$ch-rad-color: $dove-gray;
$ch-rad-font-size: 16px;

$check-unchecked: "\e911";
$check-checked: "\e912";
$radio-unchecked: "\e914";
$radio-checked: "\e910";

// errors
$error-color: $light-red;

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  display: block;
  padding: $label-padding;
  @include rem(font-size, 15px);
  font-weight: 900;
  color: $dark-blue;
  letter-spacing: 1px;

  span {
    @include rem(font-size, 13px);
    color: $darkest-gray;
    font-weight: normal;
  }

  &.plain {
    @include rem(font-size, 14px);
    font-weight: 400;
    color: $darkest-gray;
    letter-spacing: normal;
  }

  &.error {
    color: $error-color;
  }
}

// Input Fields
//
// :focus      - Focus state
//
// Markup:
// <input type="text" placeholder="Text Input" class="{{modifier_class}}">
// <input type="email" placeholder="Email Input" class="{{modifier_class}}">
// <input type="date" placeholder="Date Input" class="{{modifier_class}}" />
// <input type="password" placeholder="Password Input" class="{{modifier_class}}">
// <input type="search" placeholder="Search Input" class="{{modifier_class}}">
// <input type="tel" placeholder="Telephone Input" class="{{modifier_class}}">
// <textarea class="{{modifier_class}}" placeholder="Textarea"></textarea>
//
// Styleguide 6.1
input[type='text'],
input[type='email'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='date'],
input[type='number'],
input[data-toggle='datepicker'],
input[data-toggle='datepickerfuture'],
input[data-toggle='monthpicker'],
textarea,
select {
  box-sizing: border-box;
  width: $input-width;
  margin: $input-margin;
  padding: $input-padding;
  font-size: 1em; // leave at 1em in mobile sizes to prevent iphone zoom
  color: $input-color;
  border-top: $input-border-top;
  border-right: $input-border-right;
  border-bottom: $input-border-bottom;
  border-left: $input-border-left;
  border-radius: $input-border-radius;
  box-shadow: $input-box-shadow;
  -webkit-appearance: none;
  transition: background-color $ts $te;
  background-color: $input-background-color;

  @include from(8) {
    @include rem(font-size, $input-font-size);
  }

  &:focus,
  &.-focused {
    outline: none;
    background-color: $input-focus-bg;
    color: $input-focus-color;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $input-focus-placeholder;
      opacity: $input-focus-placeholder-opacity;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $input-focus-placeholder;
      opacity: $input-focus-placeholder-opacity;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $input-focus-placeholder;
      opacity: $input-focus-placeholder-opacity;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $input-focus-placeholder;
      opacity: $input-focus-placeholder-opacity;
    }
  }

  &.error {
    border: 1px solid $error-color;
  }

  &.-invalid {
    border: 1px solid $error-color;
  }

}

textarea {
  min-height: $textarea-minheight;
  resize: none;
}

input[type='tel'] {
  width: 55px;

  &.phone-ext {
    margin-left: 20px;
  }

  &.suffix {
    width: 65px;
  }
}

.phone-dash {
  margin: 0 2px;
}

.context {
  @include rem(font-size, 14px);
  line-height: 1.35;
}

// Select Elements
//
// :focus      - Focus state
// .red        - Red Dropdown
//
// Markup:
// <select class="{{modifier_class}}">
//   <option value="option1">Option 1</option>
//   <option value="option2">Option 2</option>
//   <option value="option3">Option 3</option>
//   <option value="option4">Option 4</option>
//   <option value="option5">Option 5</option>
// </select>
//
// Styleguide 6.2
select {
  display: block;
  background-color: $select-bg-color;
  padding: $select-padding $select-padding-right $select-padding $select-padding;
  font-size: 1em; // leave at 1em in mobile sizes to prevent iphone zoom
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: $select-arrow-img;
  background-repeat: no-repeat;
  background-position: right $select-arrow-offset center;
  border-radius: $select-border-radius;
  text-decoration: none;

  &:-moz-focusring {
    text-shadow: 0 0 0 #000;
  }

  &::-ms-expand { /* for IE 11 */
    display: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &.red {
    background-color: $light-red;
    color: $white;
    border-color: $light-red;
    font-weight: 900;
    text-transform: uppercase;
    padding: 8px 35px 8px 15px;
    letter-spacing: 0.05em;
  }
}

// Checkboxes
//
// Markup:
// <div class="checks">
//   <div class="check">
//     <input type="checkbox" id="c1" name="cb" checked>
//     <label for="c1"><span><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis.</label>
//   </div>
//   <div class="check">
//     <input type="checkbox" id="c2" name="cb">
//     <label for="c2"><span><span>Option Two</label>
//   </div>
//   <div class="check">
//     <input type="checkbox" id="c3" name="cb">
//     <label for="c3"><span><span>Option Three</label>
//   </div>
// </div>
//
// Styleguide 6.3

// Radio Buttons
//
// Markup:
// <div class="radios">
//   <div class="radio">
//     <input type="radio" id="r1" name="r" checked>
//     <label for="r1"><span><span>Option One</label>
//   </div>
//   <div class="radio">
//     <input type="radio" id="r2" name="r">
//     <label for="r2"><span><span>Option Two that wraps Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</label>
//   </div>
//   <div class="radio">
//     <input type="radio" id="r3" name="r">
//     <label for="r3"><span><span>Option Three</label>
//   </div>
// </div>
//
// Styleguide 6.4
.checks,
.radios {

  > .check {
    margin-bottom: 0;
  }

  label {
    display: inline-block;
    padding-bottom: $ch-rad-label-padding-bottom;
    margin-bottom: $ch-rad-label-margin-bottom;
    padding-left: $ch-rad-label-padding-left;
    text-indent: $ch-rad-label-text-indent;
    width: auto;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    a {
      display: inline;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  input[type="checkbox"] + label span,
  input[type="radio"] + label span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-indent: 0;
    cursor: pointer;

    &::before {
      display: block;
      position: relative;
      top: 2px;
      left: 2px;
      font-family: "icomoon";
      font-size: $ch-rad-font-size;
      color: $ch-rad-color;
    }
  }

  input[type="checkbox"] + label span {
    width: $checks-width;
    height: $checks-height;
    margin: $checks-margin;

    &::before {
      content: $check-unchecked;
    }
  }

  input[type="checkbox"]:checked + label span {

    &::before {
      content: $check-checked;
    }
  }

  input[type="radio"] + label span {
    width: $radios-width;
    height: $radios-height;
    margin: $radios-margin;

    &::before {
      content: $radio-unchecked;
    }
  }

  input[type="radio"]:checked + label span {
    &:before {
      content: $radio-checked;
    }
  }

  &.-gray-text {

    label {
      @include rem(font-size, 14px);
      color: $dark-gray;
    }
  }

}

// datepickers
input[data-toggle='datepicker'],
input[data-toggle='datepickerfuture'],
input[data-toggle='monthpicker'] {
  background-image: url('../images/calendar-light-blue.svg');
  background-repeat: no-repeat;
  background-size: 18px 21px;
  background-position: center right 10px;
}

// alt placeholder style
input {
  &.blue {
    &::placeholder {
      color: $dark-blue;
      font-style: normal;
      font-weight: 600;
      @include rem(font-size, 14px);
      letter-spacing: 1px;
    }
  }
}

// alt select style
select {
  &.blue {
    color: $dark-blue;
    font-style: normal;
    font-weight: 600;
    @include rem(font-size, 14px);
    letter-spacing: 1px;
  }
}

.input-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.input-file + label {
  @extend a.btn;
  padding: 8px 20px;
  background-color: $alt-light-gray;
  color: $port-blue;
  border-color: $alt-light-gray;
  @include rem(font-size, 15px);
  text-transform: none;
  letter-spacing: normal;

  .file-uploader & {
    background-color: $light-red;
    border-color: $light-red;
    color: $white;
    text-transform: uppercase;
    @include rem(font-size, 13px);
  }
}

.input-file:focus + label,
.input-file + label:hover {
  background-color: $white;
  border-color: $light-blue;
  color: $port-blue;
  outline: 0;
  cursor: pointer;

  .file-uploader & {
    border-color: $light-red;
    color: $light-red;
  }
}

.file-uploader {
  display: flex;
  align-items: center;

  .file-upload-filename {
    margin-left: 10px;
    @include rem(font-size, 14px);
    font-weight: 400;
  }
}

::-webkit-input-placeholder {
  color: $medium-gray;
  @include rem(font-size, 16px);
  font-style: italic;
}
::-moz-placeholder {
  color: $medium-gray;
  @include rem(font-size, 16px);
  font-style: italic;
}
:-ms-input-placeholder {
  color: $medium-gray;
  @include rem(font-size, 16px);
  font-style: italic;
}
:-moz-placeholder {
  color: $medium-gray;
  @include rem(font-size, 16px);
  font-style: italic;
}

@include from(8){

  ::-webkit-input-placeholder {
    @include rem(font-size, 15px);
  }
  ::-moz-placeholder {
    @include rem(font-size, 15px);
  }
  :-ms-input-placeholder {
    @include rem(font-size, 15px);
  }
  :-moz-placeholder {
    @include rem(font-size, 15px);
  }

}

form {

  &.right-button-style {

    position: relative;
    input {
      margin-bottom: 0;
    }
    button {
      position: absolute;
      right: -1px;
      top: 0;
      margin: auto;
    }

  }

  &.dark-background {

    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='date'],
    input[type='search'],
    textarea,
    select {
      background-color: rgba($white, 0.25);
      color: $white;

      &:focus {
        background-color: rgba($white, 0.4);
        color: $white;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $white;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $white;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $white;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $white;
        }
      }

      option {
        color: $medium-gray;
      }

    }

    ::-webkit-input-placeholder {
      color: rgba($white, 0.9);
    }
    ::-moz-placeholder {
      color: rgba($white, 0.9);
    }
    :-ms-input-placeholder {
      color: rgba($white, 0.9);
    }
    :-moz-placeholder {
      color: rgba($white, 0.9);
    }

  }

}

.radio-block {
  display: inline-flex;
  align-items: center;
  padding: 15px 15px 15px 30px;
  margin: 10px 0;
  background: $ice-blue;
  border-radius: $border-radius;

  label {
    margin-left: 10px;
    padding: 0;
  }
}

.error-message {
  margin: -10px 0 20px;
  @include rem(font-size, 14px);
  color: $error-color;
}

fieldset {

  &.radios,
  &.checks {

    .error-message {
      margin: 10px 0 0;
    }

    &.-single {

      .error-message {
        margin: 0 0 10px;
      }
    }
  }

  &.tel-input {

    .error-message {
      margin: 0 0 10px;
    }
  }
}

.individual-checks {
  margin-bottom: 20px;
}
