.centered-content {
	padding: 30px 0;
	text-align: center;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	// just slightly smaller
	.inner {
		max-width: 1000px;
	}

	h2,h3,h4,h5,h6,li {
		line-height: 1.4;
	}

	p {
		&:last-of-type{
			margin-bottom: 0;
		}
		a {
			font-weight: bold;
		}
		+ .btn {
			margin-top: 25px;
		}
	}

	.item {
		padding: 35px 0 10px;
		position: relative;
		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 50px;
			height: 1px;
			background-color: rgba($black, 0.1);
		}
		&:last-of-type{
			&:after{
				display: none;
			}
		}
	}

	@include from(8){
		padding: 50px 0;
	}

}