.event-filters {
  margin: 0 0 40px;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);

  @include from(8) {
    box-shadow: none;
  }

  &.-active {

    .event-filters-trigger {
      .plus {
        &::before {
          content: '\e904';
        }
      }
    }
  }

  .event-filters-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $alt-light-gray;
    color: $dove-gray;
    @include rem(font-size, 16px);
    font-weight: 900;
    text-decoration: none;
    border-radius: $border-radius $border-radius 0 0;

    @include from(8) {
      display: none;
    }

    .text {
      flex: 1 1 auto;
      padding: 0 10px;
      line-height: 1;

      @include between(6, 7) {
        padding: 0 20px;
      }
    }
    .plus {
      flex: 0 0 45px;
      display: block;

      &::before {
        content: '\e905';
        display: block;
        width: 45px;
        height: 52px;
        line-height: 52px;
        background: $ice-blue;
        text-align: center;
        font-size: 20px;
        font-family: $icon;
        font-weight: 600;
      }
    }
  }

  .event-filters-inner {
    display: none;
    padding: 20px 10px;

    @include between(6, 7) {
      padding: 20px;
    }

    @include from(8) {
      padding: 0;
    }
  }

  header {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
    @include rem(font-size, 12px);

    @include between(8, 9) {
      display: block;
    }

    label {
      padding: 0 13px 0 0;
      margin-right: 13px;
      border-right: 1px solid #e5e5e5;
      @include rem(font-size, 12px);
      text-transform: uppercase;
      color: $dove-gray;

      @include between(8, 9) {
        border: 0;
      }
    }
  }

  form {
    select {
      margin-bottom: 20px;

      @include from(11) {
        margin-bottom: 30px;
      }
    }
  }

  .filter-types {
    @include between(5, 7) {
      display: flex;
      select {
        flex: 1 1 33.33333%;

        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }
  }

  .timeframe {
    display: flex;
    align-items: center;
    @include between(8, 10) {
      display: block;
    }

    select,
    input[data-toggle='datepicker'],
    input[data-toggle='datepickerfuture'] {
      flex: 1 1 auto;
      margin: 0 0 30px;
      color: $dark-blue;
    }

    .to {
      display: block;
      margin: 0 10px 30px;
      text-align: center;
      @include rem(font-size, 12px);
      color: $dark-gray;
    }
  }

  .event-date-pickers {

    @include breakpoint(560px) {
      display: flex;
      justify-content: center;
    }

    @include from(8) {
      display: block;
    }

    input[data-toggle='monthpicker'] {
      margin-bottom: 0;
      color: $dark-blue;
      font-style: normal;
      font-weight: 600;
      @include rem(font-size, 14px);
      letter-spacing: 1px;

      &::-webkit-input-placeholder {
        font-style: normal;
        color: $dark-blue;
      }
      &::-moz-placeholder {
        font-style: normal;
        color: $dark-blue;
      }
      &:-ms-input-placeholder {
        font-style: normal;
        color: $dark-blue;
      }
      &:-moz-placeholder {
        font-style: normal;
        color: $dark-blue;
      }
    }

    .event-datepicker {

      @include breakpoint(560px) {
        margin: 0 10px;
      }

      @include from(8) {
        margin: 0;
      }
    }
  }

  .datepicker-month-container {
    margin-bottom: 30px;
    padding: 10px;
    border: 1px solid #ccc;
    border-top: 0;

    .datepicker-container {
      margin: 0 auto;

    }
  }
}
