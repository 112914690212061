.testimonial-component {
	padding: 30px 20px;

	@include from(8){
		padding: 60px 40px;
	}

	.inner {
		position: relative;
		max-width: 1120px;
		padding: 60px 20px 40px 40px;
		background-color: $white;
		box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, .05);

		@include from(7) {
			display: flex;
			align-items: center;
		}

		@include from(10) {
		  padding: 60px 60px;
		}
	}

	.image-side {

		@include from(7) {
			position: relative;
			flex: 0 0 200px;
			width: 200px;
			margin-right: 30px;
		}

		@include from(12) {
		  margin-right: 50px;
		}

		img {
		  display: none;

			@include from(7) {
			  display: block;
			}
		}

		&:before{
			content: "\e919";
			position: absolute;
			top: 0;
			left: 0;
			width: 45px;
			height: 45px;
			line-height: 45px;
			background-color: rgba($light-red, 0.85);
			text-align: center;
			color: $white;
			font-family: $icon;
			@include rem(font-size, 18px);

			@include from(7) {
				top: -22px;
				left: -22px;
			}

			@include from(10) {
				top: -32px;
				left: -32px;
				width: 65px;
				height: 65px;
				line-height: 65px;
				@include rem(font-size, 28px);
			}
		}
	}

	.text-side {

		@include from(7) {
			flex: 1 1 auto;
			position: relative;
		}
	}

	blockquote {
		margin-bottom: 0;

		p {

			@include from(7) {
				@include rem(font-size, 20px);
			}

			@include from(9) {
			  @include rem(font-size, 25px);
			}
		}
	}
}
