$black            : #000;
$darkest-gray     : #1e1e1e;
$dark-gray        : #333333;
$dove-gray        : #6e6e6e;
$medium-gray      : #666666;
$light-gray       : #999999;
$silver-gray      : #cccccc;
$alt-light-gray   : #f1f3f6;
$light-blue-gray  : #f7f9fa;
$white            : #fff;

$dark-blue        : #13285b;
$light-blue       : #719eb2;
$alt-light-blue   : #e6eef2;
$alt-dark-blue    : #1a4274;

$port-blue        : #00749b;
$summer-blue      : #33aee4;
$cerulean-blue    : #009BDE;
$malibu-blue      : #66ccff;
$ice-blue         : #e5eaef;
$fjord-blue       : #424971;
$marina-blue      : #4a76ad;

$light-red        : #ea4b35;
$light-red-hover  : #ff472d;
$dark-red         : #891f10;

$orange           : #ff9d33;

// social media
$twitter:    #00b6f1;
$facebook:   #3b5998;
$googleplus: #df4a32;
$pinterest:  #cb2027;
$linkedin:   #007bb6;
$youtube:    #b31217;
$instagram:  #c32aa3;
$snapchat:   #fffc00;




// Grayscale Colors
//
// Colors:
// $black                   : #000    - Black
// $darkest-gray            : #1e1e1e - Darkest Gray
// $dark-gray               : #333333 - Dark Gray
// $dove-gray               : #6e6e6e - Dove Gray
// $medium-gray             : #666666 - Medium Gray
// $light-gray              : #999999 - Light Gray
// $silver-gray             : #cccccc - Silver Gray
// $alt-light-gray          : #f1f3f6 - Alt Light Gray
// $light-blue-gray         : #f7f9fa - Light Blue Gray
// $white                   : #fff    - White
//
// Styleguide 1.1


// Brand Colors
//
// Colors:
// $dark-blue        : #13285b - Dark Blue
// $alt-dark-blue    : #1a4274 - Alt Dark Blue
// $fjord-blue       : #424971 - Fjord Blue
// $marina-blue      : #4a76ad - Marina Blue
// $port-blue        : #00749b - Port Blue
// $cerulean-blue    : #009BDE - Cerulean Blue
// $summer-blue      : #33aee4 - Summer Blue
// $malibu-blue      : #66ccff - Malibu Blue
// $light-blue       : #719eb2 - Light Blue
// $alt-light-blue   : #e6eef2 - Alt Light Blue
// $ice-blue         : #e5eaef - Ice Blue
// $light-red        : #ea4b35 - Light Red
// $light-red-hover  : #ff472d - Light Red Hover
// $dark-red         : #891f10 - Dark Red
// $orange           : #ff9d33 - Orange
//
// Styleguide 1.2


// Social Media Colors
//
// Colors:
// $twitter     : #00b6f1 - Twitter
// $facebook    : #3b5998 - Facebook
// $googleplus  : #df4a32 - Google Plus
// $pinterest   : #cb2027 - Pinterest
// $linkedin    : #007bb6 - LinkedIn
// $youtube     : #b31217 - YouTube
// $instagram   : #c32aa3 - Instagram
// $snapchat    : #fffc00 - Snapchat
//
// Styleguide 1.3
