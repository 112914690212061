.accordion {
  @include kill-bullets;
  margin-bottom: 20px;

  > li {
    border-top: 1px solid $hr-color;

    ul,
    ol {
      margin: 0 20px 40px;
      list-style-type: disc;
      font-weight: normal;

      li {
        margin-bottom: 10px;
      }
    }
  }

  &:not(.sidenav):last-of-type{
    border-bottom: 1px solid $hr-color;
  }

  &-trigger {
    @include rem(font-size, 20px);
    font-weight: 900;

    > span {
      position: relative;
      display: block;
      padding: 20px 50px 20px 0;
      color: $link-color;
      line-height: 1.2;
      text-decoration: none;
      transition: color $ts $te;

      &:hover {
        color: $dark-blue;
        cursor: pointer;
      }

      &::after {
        content: "\e905";
        position: absolute;
        top: 20px;
        right: 0;
        display: block;
        width: 32px;
        height: 32px;
        line-height: 28px;
        text-align: center;
        font-family: $icon;
        font-size: 18px;
        color: $white;
        background-color: $marina-blue;
        border: 2px solid $marina-blue;
        border-radius: 50%;
        @include transition(background-color, color);
      }
    }

    &.-active {
      > span {
        &::after {
          content: "\e904";
          color: $marina-blue;
          background-color: $white;

        }
      }
    }
  }

  &-content {
    display: none;
  }

  &.-logbooks {

    > li {
      margin-bottom: 15px;
      border: 0;
      background-color: $alt-light-gray;
    }

    &:not(.sidenav):last-of-type{
      border: 0;
    }

    .accordion-trigger {
      @include rem(font-size, 16px);
      font-weight: 900;

      > span {
        position: relative;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        padding: 20px 20px 20px 50px;
        text-decoration: none;
        color: $dark-blue;

        &:hover {
          color: $marina-blue;
        }

        &::before {
          content: "\e905";
          position: absolute;
          top: 24px;
          left: 20px;
          right: auto;
          display: block;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          font-family: $icon;
          font-size: 12px;
          color: $white;
          background-color: $port-blue;
          border: 0;
          border-radius: 50%;
          @include transition(background-color, color);
        }

        &::after {
          display: none;
        }
      }

      .course-status {
        @include rem(font-size, 13px);
        color: $black;
        font-weight: normal;
        margin-left: auto;
      }

      &.-active {
        > span {
          background-color: $dark-blue;
          color: $white;

          &::after {
            content: "\e904";
            color: $marina-blue;
            background-color: $white;
          }

          &::before {
            display: flex;
            justify-content: center;
            align-items: center;
            content: "\e904";
            color: $white;
            background-color: $dark-blue;
            border: 1px solid $white;
          }

          .course-status {
            color: $white;
          }
        }
      }
    }

    .accordion-content {
      padding: 25px;
      background-color: $white;
      border-right: 1px solid #ececec;
      border-bottom: 1px solid #ececec;
      border-left: 1px solid #ececec;
      box-shadow: 0 4px 6px -2px rgba(0, 0, 0, .35);

      p {
        @include rem(font-size, 14px);
      }
    }
  }
}
