.whats-new-component {
	padding: 40px 0;
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: contain;

	// shared
	.item {
		margin: 10px 0;
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
    	justify-content: flex-end;
    	overflow: hidden;

    	h2,h6, a {
    		color: $white;
    		max-width: 100%;
    	}

    	h2,h6 {
    		margin-bottom: 0;
    		line-height: 1.4;
    		text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    	}

		.content-wrapper {
			background-color: rgba($dark-blue, 0.75);
			padding: 0 20px 20px;
			width: 100%;
			transition: background $ts $te;
			.category {
				@include rem(font-size, 12px);
				text-transform: uppercase;
				font-weight: 900;
				letter-spacing: 0.05em;
				padding: 10px 0 20px;
				border-top: 4px solid $light-red;
				&:hover{
					text-decoration: none;
				}
			}
		}

		.popup-link {
			transition: margin $ts $te, background-color $ts $te;
			padding: 11px 20px;
			width: 100%;
			background-color: rgba($dark-blue, 0.85);
			@include rem(font-size, 12px);
			text-transform: uppercase;
			font-weight: 900;
			letter-spacing: 0.05em;
			position: relative;
			&:after{
				content: "\e903";
				font-family: $icon;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
			&:hover{
				text-decoration: none;
				background-color: rgba($dark-blue, 1);
			}
		}

		&:hover{
			.content-wrapper {
				background-color: rgba($marina-blue, 0.8);
			}
			.popup-link{
				margin-bottom: 0;
				background-color: $marina-blue;
			}
		}

		//modifiers

		&.-large{
			justify-content: space-between;
			.content-wrapper {
				background-color: transparent;
				z-index: +1;
				position: relative;
				width: 100%;
				&:before{
					content: '';
					background: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: -50px;
					z-index: -1;
				}
			}			
		}

	}

	@include from(5){
		.inner {
			@include clearfix-micro;
		}
		.item {
			height: 320px;
			width: calc(50% - 10px);
			float: left;
			margin: 10px 0;
			&.-large{
				width: 100%;
				.popup-link {
					margin-bottom: -51px;
				}
				&:hover{
					.popup-link {
						margin-bottom: 0;
					}
				}
			}
			&:nth-of-type(even){
				margin-right: 20px;
			}
		}
		.popup-link {
			margin-bottom: -40px;
		}
	}

	@include from(8){
		.inner {
			padding: 0 20px;
		}

		.item {
			width: calc(33% - 11px);
			margin: 10px 0px 10px 20px;
			&.-large {
				height: 660px;
				width: calc(33% - 11px);
				margin-left: 0;
			}
			&:nth-of-type(even){
				margin-right: 0px;
			}
			&:nth-of-type(3n+3){
				margin-left: 0;
			}
			//to offset the above math for the 3rd list item
			&:nth-of-type(3){
				margin: 10px 0px 10px 20px;
			}
		}
	}

}
