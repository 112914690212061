.-search-results {

  .search-results-loading {

    img {
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }
  }

  &[data-loading="false"] {

    .search-results-loading {
      display: none;
    }
  }

  &[data-loading="true"] {

    .search-results-loading {
      display: block;
    }
  }

	.result-count {
		border-bottom: 1px solid rgba($black, 0.2);

    &:empty {
      border: 0;
    }

		p {
			@include rem(font-size, 15px);
			font-weight: normal;
			font-style: italic;
			span {
				font-weight: bold;
				font-style: normal;
			}
		}
	}

	.result {
		padding-top: 20px;
		border-bottom: 1px solid rgba($black, 0.2);

		h4 {
			margin-bottom: 10px;

			a {
				&:hover,
				&:focus{
					color: darken($link-color, 10%);
				}
			}
		}

		span {
			&.search-highlight {	 
				font-weight: bold;
				font-style: italic;
		}
	}
}

		

  .js-search-container {
    margin-bottom: 40px;
  }
}

.search-no-results,
.search-no-keywords {
  display: none;
}
