.content-slider {
  position: relative;

  &-inner,
  &-content {
    max-width: 720px;
    margin: 20px auto;

    @include from(8) {
      margin: 40px auto;
    }

    @include from(12) {
      margin: 70px auto;
    }

    .slick-arrow {
      border-color: $white;
      background: transparent;
      &::after {
        color: $white;
      }

      &:hover,
      &:focus,
      &:active {
        background: $white;

        &::after {
          color: $port-blue;
        }
      }

      &.slick-next {
        right: 10px;
        left: auto;

        @include from(8) {
          right: 20px;
        }

        @include from(12) {
          right: 30px;
        }
      }

      &.slick-prev {
        left: 10px;
        right: auto;

        @include from(8) {
          left: 20px;
        }

        @include from(12) {
          left: 30px;
        }
      }
    }
  }

  > a {
    * {
      text-decoration: none;
    }
  }

  &-item {
    text-align: center;
    padding-top: 50%;
    position: relative;

    @include from(6) {
      padding-top: 40%;
    }

    @include from(9) {
      padding-top: 45%;
    }

    &-inner {
      padding: 20px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include from(7) {
        padding: 40px 15%;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      li,
      p,
      a:not(.btn) {
        color: $white;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  h3 {
    @include rem(font-size, 20px);
    font-weight: 900;

    a {
      color: $port-blue;
    }
  }
}
