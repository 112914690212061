.results-filter-header {
  position: relative;
  overflow: hidden;
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #e7e8ed;

  @include from(7) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 15px;
  }

  @include from(10) {
    margin: 0;
  }

  .header {

    @include rem(font-size, 13px);
    color: $medium-gray;

    @include from(11) {
      display: flex;
    }

    .results-total {
      margin: 0;
      font-weight: 400;

      @include from(11) {
        border-left: 1px solid $hr-color;
        align-self: flex-start;
        padding: 10px 18px;
        margin-left: 18px;
      }

      strong {
        font-weight: 900;
        font-style: normal;
      }
    }
  }

  .filters {
    margin: 20px 0 0;
    
    @include from(7) {
      align-self: flex-start;
      min-width: 24%;
      margin: 0;
    }

    form {
      display: flex;

      label {
        flex: 0 1 auto;
        margin: 0 10px 0 0;
        @include rem(font-size, 12px);
        color: $medium-gray;
        font-weight: 900;
        white-space: nowrap;
      }

      select {
        flex: 0 1 auto;
        margin: 0;
        color: $dark-blue;
        font-style: normal;
        font-weight: 600;
        @include rem(font-size, 14px);
        letter-spacing: 1px;
      }
    }
  }

  .view-legend {
    margin: 10px 0 0;

    @include breakpoint(350px) {
      float: right;
      margin: 0;
    }

    @include from(7) {
      position: absolute;
      bottom: 15px;
      right: 0;
      float: none;
    }
    @include from(8) {
      display: none;
    }
  }
}
