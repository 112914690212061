.cart-empty {
  padding: 20px 0;

  @include from(8) {
    padding: 12px 18px;
  }

  p {
    @include rem(font-size, 18px);
    color: $dark-blue;
    font-weight: 900;
  }
}

.cart-summary-header-text {

  @include from(8) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 20px;
  }

  h3 {
    margin: 0 0 10px;

    @include from(8) {
      margin: 0;
    }
  }

  .browse-more {
    position: relative;
    margin: 20px 0 0;

    @include from(8) {
      margin: 0;
      top: -8px;
    }

    @include from(10) {
      top: auto;
      display: flex;
    }

    .label {
      @include rem(font-size, 14px);
      font-weight: 400;

      @include from(10) {
        margin-right: 5px;
      }
    }

    ul {
      display: flex;
      @include kill-bullets;

      li {
        display: flex;
        @include rem(font-size, 14px);
        color: $medium-gray;

        &:not(:last-child) {

          &::after {
            content: '|';
            display: inline-block;
            margin: 0 5px;
          }
        }
      }
    }
  }

  .cart-info {
    font-weight: 900;
    color: $medium-gray;

    @include from(8) {
      @include rem(font-size, 18px);
    }
  }
}

.cart-order-summary-header {
  display: none;
  padding: 12px 18px;
  @include rem(font-size, 14px);
  color: $dark-gray;
  background-color: #f5f6f7;
  border-top: 1px solid #d0d2dc;

  @include from(8) {
    display: flex;
  }

  &-description {
    flex: 1 1 auto;
  }

  &-price {
    flex: 0 0 auto;
    width: 15%;
    padding-right: 20px;
    text-align: right;
  }

  &-qty {
    flex: 0 0 auto;
    width: 25%;
    text-align: center;
  }

  &-total {
    flex: 0 0 auto;
    width: 15%;
    text-align: right;

    @include from(10) {
      width: 10%;
    }
  }
}

.cart-order-summary-item {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  @include rem(font-size, 14px);
  color: $dark-gray;
  border-bottom: 1px solid #d0d2dc;

  @include from(8) {
    flex-wrap: nowrap;
    padding: 12px 18px;
  }

  .mobile-label {
    margin-bottom: 10px;
    @include rem(font-size, 11px);
    text-transform: uppercase;
    color: $medium-gray;

    @include from(8) {
      display: none;
    }
  }

  &-description {
    flex: 1 1 auto;
    width: 100%;
    margin: 0 0 15px;
    @include rem(font-size, 18px);
    font-weight: 900;
    color: $dark-blue;

    @include from(8) {
      width: auto;
    }

    .eyebrow {
      margin: 0;
      @include rem(font-size, 11px);
      color: $light-gray;
      letter-spacing: .12em;
    }

    p {
      margin: 0;
      color: $dark-gray;
      @include rem(font-size, 14px);
    }
  }

  &-price {
    flex: 0 0 auto;
    width: 33.333%;
    padding-right: 20px;
    text-align: left;
    font-weight: 900;

    @include from(8) {
      width: 15%;
      text-align: right;
    }

    .strike {
      display: inline-block;
      margin-bottom: 3px;
    }
  }

  &-qty {
    flex: 0 0 auto;
    width: 33.333%;
    text-align: center;
    font-weight: 900;

    @include from(8) {
      width: 25%;
    }

    input {
      width: 40px;
      margin: 0;
      text-align: center;
      @include rem(font-size, 14px);
      padding: 5px;
    }

    .update {
      margin: 5px 0 0;
      @include rem(font-size, 12px);

      .divider {

        &::after {
          content: '\2022';
          margin: 0 4px;
          color: #d0d2dc;
        }
      }
    }
  }

  &-total {
    flex: 0 0 auto;
    width: 33.333%;
    text-align: right;
    font-weight: 900;

    @include from(8) {
      width: 15%;
    }

    @include from(10) {
      width: 10%;
    }
  }

  &.-no-qty {

    .cart-order-summary-item-price,
    .cart-order-summary-item-total {

      @include to(7) {
        width: 50%;
      }
    }
  }
}

.cart-totals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px 0;
  text-align: right;

  &.-show-promo-code {

    .cart-total {
      border-top: 0;
    }
  }

  p {
    margin: 0;
    color: $black;
    font-weight: 900;

    &.-discount {
      color: $light-gray;
    }
  }

  .cart-subtotal {
    padding: 0 0 15px;
  }

  .cart-total {
    padding: 15px 0 0;
    border-top: 1px solid $hr-color;

    p {
      margin: 0 0 10px;
      @include rem(font-size, 18px);
    }
  }

  .cart-promo-code {
    margin: 20px 0;
    padding: 20px;
    background: #f5f6f7;
    width: 100%;
    text-align: left;

    .promo-code-fields {
      display: flex;
      align-items: flex-start;

      input[type='text'] {
        flex: 1 1 auto;
        background-color: $white;
      }

      input[type='submit'] {
        flex: 0 0 auto;
        width: 90px;
        margin-left: 20px;
        background-color: #d1d2d3;
        color: $black;
        border: 0;
      }
    }
  }

  .cart-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;

    .-edit-cart {
      margin-right: 20px;
      @include rem(font-size, 12px);
      font-weight: 900;
    }
  }
}

.title-contact-info {
  display: block;
  margin-top: 10px;
  @include rem(font-size, 14px);
  font-weight: 400;
  color: $dark-gray;

  @include from(8) {
    display: inline-block;
    margin: 0 0 0 5px;
    padding-left: 10px;
    border-left: 1px solid #8f8f8f;
  }
}

.cart-customer-info {
  margin: 40px 0;

  &.-payment {
    margin: 60px 0 70px;
  }

  h3 {
    position: relative;

    .-print {
      position: absolute;
      top: 8px;
      right: 0;
      text-decoration: none;
      @include rem(font-size, 12px);
    }
  }
}

.cart-customer-details {

  p {
    margin-bottom: 5px;
    @include rem(font-size, 14px);

    @include from(10) {
      @include rem(font-size, 16px);
    }

    &.-edit {
      @include rem(font-size, 12px);
      font-weight: 900;
    }
  }

  .cart-order-number {

    p {
      margin-bottom: 20px;
      @include rem(font-size, 18px);
      font-weight: 900;
      color: $dark-blue;
    }
  }

  .cart-customer-details-order {
    margin: 0 0 20px;

    @include from(8) {
      width: calc(50% - 20px);
      padding-right: 20px;
      margin-bottom: 0;
    }

    @include from(12) {
      width: calc(50% - 50px);
      padding-right: 50px;
      margin-bottom: 0;
    }

    .row {
      display: flex;
      justify-content: space-between;

      p {
        width: 50%;
      }
    }
  }

  .cart-customer-details-address {

    @include from(8) {
      width: calc(50% - 21px);
      padding-left: 20px;
      border-left: 1px solid #c8cad5;
    }

    @include from(12) {
      width: calc(50% - 51px);
      padding-left: 50px;
    }
  }
}

.cart-confirmation {

  h3 {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 5px;
  }

  .cart-customer-details {
    padding: 20px;
    background: #f5f6f7;

    @include from(8) {
      padding: 40px;
    }
  }

  .cart-customer-details-row {

    @include from(8) {
      display: flex;
    }
  }
}
