.pagination-group,
.ko-pagination-group,
.search-pagination-group {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include from(4) {
    justify-content: space-between;
  }

  a {
    background-color: $light-blue-gray;
    @include rem(font-size, 13px);
    color: $light-blue;
    border: 1px solid $ice-blue;
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    margin-left: -1px;
    transition: background $ts $te color $ts $te;

    &:hover {
      text-decoration: none;
      background-color: $light-blue;
      color: $light-blue-gray;
    }
  }

  .pagination-group-actions,
  .ko-pagination-group-actions,
  .search-pagination-group-actions {
    display: none;

    @include from(4) {
      display: flex;
    }

    a {

      &:first-of-type {
        border-radius: $border-radius 0 0 $border-radius;
      }

      &:last-of-type {
        border-radius: 0 $border-radius $border-radius 0;
      }
    }
  }

  .pagination,
  .ko-pagination,
  .search-pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    li {
      display: inline-block;
      color: $light-gray;
      @include rem(font-size, 13px);
      text-align: center;
      padding: 0;
      margin-bottom: 0;
      font-weight: bold;

      &::before {
        display: none;
      }

      &.current,
      &.ko-current {
        background-color: $dark-blue;
        color: $white;
        width: 38px;
        height: 38px;
        line-height: 38px;
        border: 1px solid $ice-blue;
      }

      &.dots,
      &.ko-dots {
        width: 38px;
        height: 38px;
        line-height: 38px;
        background-color: $light-blue-gray;
        border: 1px solid $ice-blue;
        margin-left: -1px;
      }

      &:first-child {

        &.current,
        &.ko-current,
        a {
          border-radius: $border-radius 0 0 $border-radius;
        }
      }

      &:last-child {

        &.current,
        &.ko-current,
        a {
          border-radius: 0 $border-radius $border-radius 0;
        }
      }
    }
  }
}
