// sitecore specific - to prevent components collapsing in experience editor

body.scee {

  .two-up-component {
    .left-side,
    .right-side {
      min-width: 200px;
      min-height: 200px;
    }
  }

  .footer-logos {
    min-width: 100%;
    min-height: 200px;
    .affil-logo {
      min-width: 100px;
      min-height: 100px;
    }
  }

  .hero-bottom {
    overflow: visible;

    .ctas {
      bottom: 58px;
    }
  }

  .connecting-cruise-industry {

    ul {

      .item {

        a {
          flex-wrap: wrap;
        }

        .text {
          flex-basis: 100%;
          position: relative;
          opacity: 1;
          visibility: visible;
          padding: 10px 0;

          @include from(9) {
            padding: 10px;
          }
        }

        .cta {
          display: block;
          visibility: visible;
          opacity: 1;
        }

        @include from(9) {

          &:hover {

            a {
              background: #369;
              height: 100%;
              top: 0px;
              padding-top: 65px;
              box-shadow: 4px 4px 16px 0px rgba(0,0,0,0.25);

              @include from(11) {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

}
