.clia-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
  padding: 10px 0;
  margin: 0 20px;
  border-bottom: 1px solid transparent;

  @include from($nav-break) {
    padding: 0;
  }

  @include from(12) {
    max-width: 1170px;
    height: 108px;
    margin: 0 auto;
  }

  .clia-logo-desktop {
    display: none;

    @include from($nav-break) {
      display: block;
    }
  }

  .clia-main-nav,
  .clia-utility-nav {
    display: none;

    @include from($nav-break) {
      display: block;
    }
  }

  .clia-logo {
    display: block;
    flex: none;

    @include from($nav-break) {

      .clia-logo-mobile {
        display: none;
      }
    }

    img {
      width: 70px;

      @include from($nav-break) {
        width: 140px;
      }

      @include from(11) {
        width: 160px;
      }

      @include from(12) {
        width: 180px;
        margin-top: -4px;
      }
    }
  }

  .header-right {
    flex: none;

    @include from($nav-break) {
      flex: 1 1 0;
      height: 94px;
    }

    @include from(10) {
      height: 95px;
    }

    @include from(11) {
      height: 96px;
    }

    @include from(12) {
      height: 108px;
    }
  }

  .icon-menu {
    display: block;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    line-height: 30px;
    font-size: 0;
    text-decoration: none;
    color: $dark-blue;

    &::before {
      position: relative;
      left: -2px;
      font-size: 32px;
    }
  }

  .mobile-menu {

    @include from($nav-break) {
      display: none;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0;

      li {
        position: relative;
        display: flex;
        @include rem(font-size, 12px);

        &:not(:last-child) {
          padding: 0 15px;
          border-right: 1px solid rgba($dark-blue, .35);
        }

        a {
          display: block;
          height: 30px;
          line-height: 30px;
          text-decoration: none;
          color: $dark-blue;
        }

        &.username {
          border-right: 0;
          padding-right: 0;

          a {
            color: $black;
          }
        }

        &.cart {
          position: relative;

          a {
            display: block;
            font-size: 0;
            height: auto;
            line-height: normal;

            &::before {
              content: '\e921';
              margin: 0;
              font-family: $icon;
              @include rem(font-size, 20px);
              color: #b2b2b2;
            }
          }

          .cart-total {
            display: inline-block;
            vertical-align: top;
            margin-left: 4px;
            padding: 3px 10px;
            background-color: $port-blue;
            @include rem(font-size, 12px);
            border-radius: 12px;
            color: $white;
            font-weight: 900;

            @include from(12) {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

.page--home {

  .clia-header {
    border-bottom: 1px solid rgba($white, 0.5);

    .icon-menu {
      color: $white;
    }

    .mobile-menu ul li {

      &:not(:last-child) {
        border-right: 1px solid rgba($white, .35);
      }

      a {
        color: $white;
      }
    }
  }
}
