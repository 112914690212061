.events-calendar {

  .filter-group {
    padding-top: 0px;
    border-top: 0;
  }
}

.events,
.training {
  margin: 0 0 20px;

  @include from(10) {
    margin: 0 0 40px;
  }

  .event,
  .course {
    padding: 20px 10px;
    border-left: 3px solid transparent;
    @include transition(border-color);

    &:nth-child(even) {
      background-color: #f7f9fa;
    }

    &:hover {
      border-left: 3px solid $light-red;
    }

    @include from(10) {
      display: flex;
      align-items: center;
      padding: 25px 20px;
    }

    .date,
    .course-logo {
      margin: 0 0 5px;
      @include rem(font-size, 14px);
      color: $port-blue;
      letter-spacing: 1px;

      @include from(10) {
        padding: 0 20px 0 0;
        margin: 0 20px 0 0;
        border-right: 1px solid $hr-color;
        text-align: center;
      }

      .month {
        display: inline-block;
        @include rem(font-size, 12px);
        font-weight: 900;

        @include from(10) {
          display: block;
        }
      }

      .day {
        display: inline-block;
        margin: 0 5px;

        @include from(10) {
          display: block;
          margin: 0;
          @include rem(font-size, 30px);
          font-weight: 900;
        }
      }

      .year {
        display: inline-block;
        color: $medium-gray;
        font-weight: 900;

        @include from(10) {
          display: block;
          @include rem(font-size, 12px);

        }
      }
    }

    .course-logo {
      display: none;

      a {
        display: block;        
      }

      @include from(10) {
        display: block;
        width: 100px;
        flex: 0 0 auto;
        padding: 0 20px 0 0;
        margin: 0 20px 0 0;
        border-right: 1px solid $hr-color;
        text-align: center;
      }
    }

    .course-details {
      flex: 1 1 auto;
    }

    .event-details,
    .course-details {

      .label {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        padding: 3px 6px;
        background: $light-red;
        color: $white;
        @include rem(font-size, 10px);
        font-weight: 900;
      }

      .event-name,
      .course-name {
        @include rem(font-size, 18px);

        @include from(10) {
          @include rem(font-size, 21px);
        }
      }

      a {
        color: $dark-blue;
        line-height: 1.35;
        font-weight: 900;
        text-decoration: none;

        &:hover {
          color: $marina-blue;
        }
      }

      p {
        margin: 0;
        @include rem(font-size, 14px);
        color: $dark-gray;
      }

      .status {
        font-weight: 700;
      }
    }
  }
}
