.free-text{
	padding: 40px 0 20px;

	h6 {
		text-transform: uppercase;
		margin-bottom: 10px;
		@include rem(font-size, 16px);
	}
	p {
		@include rem(font-size, 14px);
	}
}
