.callout-component {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	position: relative;
	padding: 40px 0;
	text-align: center;

	// make the inner a bit smaller than the rest of the site
	.inner {
		max-width: 1000px;
	}

	//make anything they put in this sucker white
	h1,h2,h3,h4,h5,h6,li {
		color: $white;
		line-height: 1.4;
	}
	p {
		color: $white;
	}
	//any links wrapper in a p tag that aren't buttons also need to be white
	p a:not(.btn){
		color: $white;
		text-decoration: underline;
	}

	a.btn {
		margin: 5px;
	}

	@include from(4){
		padding: 60px 0;
	}

	@include from(8){
		padding: 100px 0;
	}

}
