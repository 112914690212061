.promo-bar {
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding: 15px;
  background-color: $port-blue;
  color: $white;
  @include clearfix-micro;

  &.-flush {
    margin-bottom: 0;
  }

  // &::before {
  //   content: '\e920';
  //   flex: 0 0 auto;
  //   width: 30px;
  //   margin-right: 10px;
  //   font-family: $icon;
  //   color: #80bdd3;
  //   @include rem(font-size, 25px);
  //   font-weight: 400;
  // }

  p {
    flex: 1 1 auto;
    margin: 0;
    line-height: 1.25;
  }

  a {
    color: $white;
    font-weight: 700;
  }

  .accordion-content & {

    p {
      font-weight: 700;
    }
  }
}
