.membership-agent-intro {
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid $hr-color;
}

.header-split-text {
  margin: 30px 0 20px;
  padding: 0 0 10px;
  border-bottom: 1px solid $hr-color;

  @include from(8) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {

    @include from(8) {
      margin-bottom: 0;
    }
  }

  a {
    @include rem(font-size, 14px);
    color: $port-blue;
  }
}

.boost-biz {
  margin: 0 0 30px;
  border: 1px solid #d9e3ee;

  @include from(8) {
    display: flex;
  }

  &-left {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: #e9eff5;

    @include from(8) {
      width: 50%;
      padding: 20px;
    }
  }

  &-right {
    padding: 30px;

    @include from(8) {
      width: 50%;
      padding: 20px;
    }

    p {
      @include rem(font-size, 14px);

      &:last-child {
        margin: 0;
      }
    }
  }
}

.term-checks {
  padding: 20px 0;
  margin: 0 0 30px;
  border-top: 1px solid $hr-color;
  border-bottom: 1px solid $hr-color;
}
