.filter-three-up {
  padding: 40px 0;
  .filter-form {
    h2 {
      padding-right: 10px;
    }
    form {
      select {
        font-weight: 900;
        color: $dark-blue;
        @include rem(font-size, 15px);
        background-color: $white;
        padding: 8px 40px 8px 10px;
      }
    }
    @include from(6){
      form {
        display: flex;
        flex-wrap: wrap;
        select {
          width: auto;
          margin-right: 10px;
          &:last-of-type{
            margin-right: 0;
          }
        }
      }
    }
    @include from(10){
      display: flex;
      justify-content: space-between;
      align-items: center;
      form {
        display: flex;
        select {
          padding: 10px 50px 10px 20px;
        }
      }
    }
  }

  .filter-results {
    padding: 20px 0 0;

    .item {
      position: relative;
      margin: 0 0 40px;
      padding-bottom: 10px;

      > a {
        width: 100%;
      }

      img {
        margin-bottom: 15px;
        width: 100%;
      }

      .item-content {
        position: relative;

        .cat-date {
          @include rem(font-size, 12px);
          margin-bottom: 15px;
          a,
          span {
            color: $dark-blue;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            font-weight: 900;
            &:after{
              content: '|';
              display: inline-block;
              margin: 0 1px 0 5px;
            }
            &:last-of-type:after{
              display: none;
            }
          }
        }

        h4 {
          line-height: 1.3;
          a {
            &:hover{
              color: darken($light-blue, 10%);
              text-decoration: none;
            }
          }
        }

      }

      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 50px;
        background-color: $light-blue;
        transition: width 0.5s $te;
      }

      &:hover{

        &:after{
          width: 100%;
        }

      }

    }

    @include from(5) {
      padding-top: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .item {
        width: calc(50% - 17px);
        margin: 20px 30px 20px 0;
        &:nth-of-type(2n) {
          margin-right: 0px;
        }
      }
    }

    @include from(10){
      .item {
        width: calc(33% - 17px);
        margin: 40px 30px 40px 0px;
        &:nth-of-type(2n) {
          margin-right: 30px;
        }
        &:nth-of-type(3n){
          margin-right: 0;
        }
      }
    }

  }

  .pagination-group {
    padding-top: 30px;
    border-top: 1px solid rgba($black, 0.1);
  }

}
