.inner {
	max-width: $site-max-width;
	margin: auto;
	padding: 0 20px;

	@include from(8) {
	  padding: 0 40px;
	}

	&.-page-container {
		padding: 20px;

		@include from(8) {
		  padding: 40px;
		}

		@include from(11) {
		  padding: 60px 40px;
		}
	}
}

// column layouts
.one-column {
	max-width: 770px;
	margin: 0 auto;

	.content-column {

		&.-centered {
			text-align: center;
		}

		&.-not-found {
			text-align: center;

			h3 {
				@include rem(font-size, 25px);
			}

			img {
				display: block;
				margin: 0 auto 20px;
			}
		}
	}
}

.two-column {
	//padding: 0 20px;

	.side-column,
	.content-column {
		margin: 0 0 20px;
	}

	@include from(8) {
		display: flex;

		&.-reverse {
			flex-direction: row-reverse;

			.side-column {
				margin: 0 0 0 35px;
			}
		}

		.side-column {
			margin: 0 35px 0 0;
			flex: 0 1 30%;

			&.right {
				margin: 0 0 0 35px;
			}
		}

		.content-column {
			flex: 0 1 70%;
			margin-bottom: 0;
		}
	}

	@include from(10) {

		.side-column {
			flex: 0 1 28%;
		}

		.content-column {
			flex: 0 1 72%;
		}

		&.-thin {

			.side-column {
				flex: 0 1 20%;

				&.right {
					margin: 0 0 0 50px;
				}
			}

			.content-column {
				flex: 0 1 80%;
			}
		}
	}
}

// images to have blue overlay based on class
.has-blue-overlay{
  @include blue-gradient-image-overlay;
}

//component background colors
.-gray {
  background-color: $light-blue-gray;
}

.-alt-gray {
	background-color: $alt-light-gray;
}

.-white{
  background-color: $white;
}

// to add shadow on the top of some components
.-top-shadow{
	box-shadow: inset 0px 5px 5px 0 rgba(0, 0, 0, 0.05);
}

.page {
  position: relative;
  height: 100%;
  transition: right $ts $te;
  right: 0;
  z-index: 1;
  background: $white;
}

.blocker {
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  top: 0;
  right: 0;
  transition: all $ts $te;
  background: rgba($black, 0.4);
}


html.-js-menu-open {

  .page {
    right: 270px;
    overflow: hidden;

    @include breakpoint(337px) {
      right: 80%;
    }

    @include breakpoint(400px) {
      right: 320px;
    }
  }

  .blocker {
    z-index: 2;
    right: 270px;
    opacity: 1;
    visibility: visible;

    @include breakpoint(337px) {
      right: 80%;
    }

    @include breakpoint(400px) {
      right: 320px;
    }
  }
}
