.home-hero {
  position: relative;
  z-index: 1;

  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: -76px;
    padding-top: 76px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    overflow: hidden;

    // so content will wrap on IE
    * {
      max-width: 100%;
    }

    @include from($nav-break) {
      margin-top: -96px;
      padding-top: 96px;
    }

    @include from(11) {
      margin-top: -108px;
      min-height: 770px;
      height: 770px; // have to add this for IE10
    }
  }

  &-text {
    padding: 75px 40px 90px;

    h1 {
      @include rem(font-size, 28px);
      color: $white;
      line-height: 1.35;
      text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.35);
    }

    p {
      @include rem(font-size, 12px);
      color: $white;
      text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.35);
    }

    .icon-play {
      &::before {
        position: relative;
        top: -2px;
        margin-right: 10px;
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    a {
      color: $white;
      font-weight: 900;
      text-decoration: none;

      &::before {
        margin-right: 5px;
      }

      &:hover {
        color: rgba($white, 0.65);
      }
    }

    @include from(7) {
      h1 {
        @include rem(font-size, 40px);
      }
      p {
        @include rem(font-size, 13px);
      }
      .icon-play {
        &::before {
          font-size: 24px;
        }
      }
    }

    @include from(9) {
      h1 {
        @include rem(font-size, 52px);
      }
      p {
        @include rem(font-size, 14px);
      }
      .icon-play {
        &::before {
          font-size: 28px;
        }
      }
    }

    @include from(11) {
      margin-top: -197px;

      h1 {
        @include rem(font-size, 65px);
      }
      p {
        @include rem(font-size, 16px);
      }
      .icon-play {
        &::before {
          font-size: 32px;
        }
      }
    }
  }

  &-slider {
    width: 100%;
    margin-top: -76px;
    text-align: center;
    overflow: hidden;

    @include from($nav-break) {
      margin-top: -96px;
    }

    @include from(11) {
      margin-top: -108px;
    }

    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
      > div {
        height: 100%;
      }
    }

    a.home-hero-slider-item {
      text-decoration: none;
    }

    &-item {
      height: 100%;
      padding-top: 76px;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;

      @include from($nav-break) {
        padding-top: 96px;
      }

      @include from(11) {
        min-height: 770px;
        height: 770px; // have to add this for IE10
        padding-top: 100px;
        padding-bottom: 210px;
        display: flex;
        align-items: center;

        .home-hero-text {
          margin-top: 70px;
        }
      }

    }

    .slick-arrow {
      border-color: $white;
      background: transparent;

      @include from(9) {
        top: calc(50% - 10px);
      }

      @include from(11) {
        top: calc(50% - 95px);
      }

      &::after {
        color: $white;
      }

      &:hover,
      &:focus,
      &:active {
        background: $white;

        &::after {
          color: $port-blue;
        }
      }

      &.slick-next {
        right: 10px;
        left: auto;

        @include from(8) {
          right: 20px;
        }

        @include from(12) {
          right: calc(50% - 600px);
        }
      }

      &.slick-prev {
        left: 10px;
        right: auto;

        @include from(8) {
          left: 20px;
        }

        @include from(12) {
          left: calc(50% - 600px);
        }
      }
    }
  }
}

.hero-bottom {
  position: relative;
  z-index: 2;
  padding: 10px;
  background-color: $marina-blue;

  @include from(11) {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 100px 0 0;
    background-color: transparent;
    overflow: hidden;
  }

  .inner-mask {
    @include from(11) {
      position: relative;
      display: block;
      width: 100%;
      background-color: rgba($marina-blue, 0.85);
    }
  }

  .inner {
    padding: 0;
    margin-top: -50px;

    @include from(11) {
      position: relative;
      margin-top: 0;
    }
  }

  .ctas {

    @include from(6) {
      display: flex;
      justify-content: center;
    }

    @include from(11) {
      position: absolute;
      bottom: 0;
      right: 20px;
      width: 50%;
      overflow: visible;
    }
  }

  .cta {
    position: relative;
    display: flex;
    flex-direction: column;
		align-items: flex-start;
    justify-content: space-between;
		margin: 10px 0;
    overflow: visible;
    background-color: #131b4d;
    text-align: center;
    text-decoration: none;
    @include transition(margin, background-color);

    @include from(6) {
      width: 50%;
      max-width: 390px;
    }

    @include from(11){
      height: 220px;
      margin: 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/pattern-wave-2.svg);
        background-size: 125px 125px;
        opacity: 0;
        //@include transition(opacity); removing: this makes the animation jerky
      }

      &:hover{
        margin-top: -57px;
        background-color: #2e487b;

        &::before {
          opacity: 1;
        }
  		}
  	}

  	h6 {
      max-width: 350px;
      margin-bottom: 0;
      padding: 10px 30px;
      @include rem(font-size, 18px);
  		line-height: 1.4;
      color: $white;

      @include from(11) {
        margin: 20px 0 0;
        padding: 10px;
      }
  	}

		.content-wrapper {
      position: relative;
      z-index: 2;
      margin: 0 auto;
			padding: 0 20px 20px;
      // to center in IE10
      width: 100%;

      .category {
        display: inline-block;
        padding: 10px 10px 10px;
        @include rem(font-size, 11px);
				text-transform: uppercase;
				font-weight: 900;
				letter-spacing: 0.05em;
				border-top: 4px solid $light-red;
        color: rgba($white, 0.8);

        &:hover{
					text-decoration: none;
				}
			}
		}

		.popup-link {
      position: relative;
      width: 100%;
      padding: 20px 20px;
      background-color: #385183;
			@include rem(font-size, 12px);
			text-transform: uppercase;
			font-weight: 900;
			letter-spacing: 0.05em;
			text-align: left;
      color: $white;
      @include transition(margin, background-color);

      @include from(6) {

        &:hover {

          &::after {
            right: 17px;
          }
        }
      }

      @include from(11) {
        position: absolute;
        bottom: -57px;
      }

      &::after{
				content: "\e903";
				font-family: $icon;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
        @include transition(right);
			}

      &:hover{
				text-decoration: none;
			}
		}

    &.-agents {
      background-color: #183567;

      @include from(11) {

        &:hover {
          background-color: #285285;
        }
      }

      .popup-link {
        background-color: #305887;
      }
    }
	}

  .about {
    max-width: 780px;
    margin: 0 auto;
    padding: 40px 20px;
    color: $white;

    @include from(11) {
      max-width: none;
      margin: 0;
    }

    h6 {
      margin: 0 0 10px;
      @include rem(font-size, 11px);
      line-height: 1.4;
      color: $white;
      text-transform: uppercase;

      @include from(11) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    p {
      @include rem(font-size, 14px);

      &:last-of-type {
        margin: 0;
      }
    }

    a {
      color: $white;
      font-weight: 900;
      text-decoration: none;
    }

    @include from(6) {
      h6 {
        @include rem(font-size, 12px);
      }
      p {
        @include rem(font-size, 16px);
      }
    }

    @include from(11) {
      width: 50%;
      text-align: left;
      padding: 35px;
    }
  }
}
