.login-container {
  padding: 40px;
  background-color: $alt-light-gray;
  border-radius: $border-radius;

  input[type='text'],
  input[type='password'] {
    background-color: $white;
  }

  .submit-row {
    margin-top: 15px;

    input[type='submit'],
    a {
      margin-right: 10px;
    }
  }

  @include from(6) {
    max-width: 450px;
    margin: 0 auto 30px;
  }
}
