.connecting-cruise-industry {
  background-color: #104076;
  background-image: url(../images/pattern-wave.svg);
  background-size: 125px 125px;

  @include from(9) {
    margin-top: 35px;

    .inner {
      padding: 0 20px;
    }
  }

  ul {
    @include kill-bullets;

    @include from(10) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }

    .item {
      border-bottom: 1px solid rgba($white, 0.2);

      @include from(10) {
        position: relative;
        width: 20%;
        height: 300px;
        display: block;
        border-bottom: 0;

        &:not(:last-of-type)::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: 1px;
          height: 60%;
          margin: 28% 0 8%;
          background-color: rgba($white, 0.2);
        }

        &:hover {

          a {
            background: #369;
            height: 380px;
            top: -30px;
            padding-top: 40px;
            box-shadow: 4px 4px 16px 0px rgba(0,0,0,0.25);

            @include from(11) {
              height: 412px;
            }
          }

          &::after {
            display: none;
          }

          .text {
            visibility: visible;
            opacity: 1;
          }

          .cta {
            opacity: 1;
            visibility: visible;
            display: block;
            @include transition(background-color);

            &:hover {
              background-color: rgba($white, 0.3);
            }
          }
        }

        &.js-next-active {
          &::after {
            display: none;
          }
        }
      }

      @include from(11) {
        height: 332px;
      }

      a {
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px 0;
        color: $white;
        text-decoration: none;
        @include transition(background-color);

        @include from(10) {
          display: block;
          text-align: center;
          padding: 65px 0px 90px;
          height: 100%;
          top: 0;
          @include transition(height, top, padding);
        }
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 22px 0 0;
        background: $white;
        width: 67px;
        height: 67px;
        border-radius: 50%;

        @include from(10) {
          margin: 0 auto 10px;
        }

        @include from(11) {
          width: 90px;
          height: 90px;
        }
      }

      .stat {
        line-height: 1.35;

        @include between(5, 9) {
          width: 18%;
        }

        @include from(10) {
          line-height: 1.15;
          margin: 0 15px;
        }


        &-data {
          display: block;
          @include rem(font-size, 30px);
          font-weight: 900;

          @include from(10) {
            @include rem(font-size, 28px);
          }

          @include from(11) {
            @include rem(font-size, 50px);
          }
        }

        &-type {
          display: block;

          @include from(10) {
            @include rem(font-size, 16px);
          }

          @include from(11) {
            @include rem(font-size, 18px);
          }
        }
      }

      .cta {
        display: none;
        visibility: hidden;
        opacity: 0;
        @include transition(opacity);

        @include from(10) {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 50px;
          padding: 0 20px;
          @include rem(font-size, 12px);
          text-align: left;
          line-height: 50px;
          background-color: rgba($white, 0.2);

          &:after{
    				content: "\e903";
    				font-family: $icon;
    				position: absolute;
    				right: 20px;
    				top: 50%;
    				transform: translateY(-50%);
    			}
        }
      }

      .text {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        padding: 0 20px;
        margin: 10px 0 0;
        @include transition(opacity 1s);

        @include between(5, 9) {
          visibility: visible;
          opacity: 1;
          position: relative;
          align-self: center;
          margin: 0;
          width: calc(80% - 67px);
        }

        p {
          @include rem(font-size, 13px);
          line-height: 1.4;
          margin: 10px 0 0;

          @include from(11) {
            @include rem(font-size, 15px);
            margin: 0;
          }
        }
      }

      &.-passengers {
        .icon {
          img {
            width: 40%;

            @include from(11) {
              width: 35%;
            }
          }
        }
      }

      &.-cruise-lines {
        .icon {
          img {
            width: 50%;

            @include from(11) {
              width: 45%;
            }
          }
        }
      }

      &.-partners {
        .icon {
          img {
            width: 70%;

            @include from(11) {
              width: 65%;
            }
          }
        }
      }

      &.-agencies {
        .icon {
          img {
            width: 60%;

            @include from(11) {
              width: 55%;
            }
          }
        }
      }

      &.-agents {
        .icon {
          img {
            width: 45%;
          }
        }
      }
    }
  }
}
