.logbooks-header {
  margin-bottom: 20px;

  @include from(10) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  h3 {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 5px;
    @include rem(font-size, 13px);
    color: $dark-gray;

    @include from(10) {
      margin-bottom: 0;
    }
  }
}

.logbooks-submit-row {

  @include from(6) {
    display: flex;
    align-items: center;
  }

  .btn,
  input[type='submit'] {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px 20px;

    &[disabled='disabled'] {
      background-color: $alt-light-gray;
      border-color: $alt-light-gray;
      color: $medium-gray;
    }

    @include from(6) {
      width: auto;
      margin: 0 20px 0 0;
    }
  }

  .logbooks-submit-instructions {
    flex: 1 1 auto;

    p {
      margin: 0;
      @include rem(font-size, 14px);
    }

    &.-links {
      overflow: hidden;

      ul {
        @include kill-bullets;
        display: flex;
        justify-content: center;

        @include from(6) {
          justify-content: flex-end;
        }

        li {
          display: flex;
          border: 0;
          @include rem(font-size, 12px);
          color: $dark-gray;

          &:not(:last-child)::after {
            content: '|';
            margin: 0 5px;
          }
        }

        a {
          color: $port-blue;
        }
      }
    }
  }

  .accordion-content & {
    padding-top: 30px;
    border-top: 1px solid $hr-color;

    .btn {
      @include rem(font-size, 10px);
    }
  }
}

.logbooks-completed-certifications {
  margin-top: 50px;
  border-bottom: 1px solid $hr-color;

  @include from(8) {
    margin-top: 80px;
  }

  h4 {
    margin-bottom: 10px;
    @include rem(font-size, 16px);
    color: $dark-gray;

    @include from(7) {
      margin: 0;
    }

    @include between(8,9) {
      margin-bottom: 10px;
    }
  }

  &-row {
    padding: 20px 0;
    border-top: 1px solid $hr-color;

    @include from(7) {
      display: flex;
      justify-content: space-between;
    }

    @include between(8, 9) {
      display: block;
    }
  }

  &-complete-date {

    p {
      display: flex;
      align-items: center;
      margin: 0;
      @include rem(font-size, 13px);

      &.-completed {
        @include icon(check-circle, after) {
          display: inline-block;
          margin-left: 15px;
          @include rem(font-size, 24px);
          color: $marina-blue;
        };
      }
    }
  }
}

.-files {
  @include icon(files);
}

.-edit {
  font-size: 0;

  @include icon(pencil) {
    text-decoration: none;
    @include rem(font-size, 20px);
  };

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.-delete {
  font-size: 0;

  @include icon(delete) {
    text-decoration: none;
    @include rem(font-size, 20px);
  };

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.logbook-callout {
  padding: 20px;
  margin-bottom: 12px;
  border-top: 3px solid $port-blue;
  background-color: $light-blue-gray;

  h4 {
    @include rem(font-size, 18px);
    margin-bottom: 3px;
  }

  p {
    margin-bottom: 15px;

    &.-credits {
      font-style: italic;
      color: $dark-gray;
    }
  }

  .btn {
    @include rem(font-size, 10px);
  }

  table {

    thead {
      background-color: #e5e9eb;
    }
  }
}
