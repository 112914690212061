.header-section-slider {
  height: 100%;

  a.header-section-slider-item {
    text-decoration: none;
  }

  &-item {
    height: 100%;
    width: 100%;
    display: flex;

    &-image {
      padding-top: 48px;
      height: 300px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  .slick-dots {
    display: none;
  }

  .slick-arrow {
    border-color: $white;
    background: transparent;
    &::after {
      color: $white;
    }

    &:hover,
    &:focus,
    &:active {
      background: $white;

      &::after {
        color: $port-blue;
      }
    }

    &.slick-next {
      right: 10px;
      left: auto;

      @include from(8) {
        right: 20px;
      }

      @include from(12) {
        right: calc(50% - 600px);
      }
    }

    &.slick-prev {
      left: 10px;
      right: auto;

      @include from(8) {
        left: 20px;
      }

      @include from(12) {
        left: calc(50% - 600px);
      }
    }
  }
}