// tables:

$logbook-1: 7;
$logbook-2: 12;

// mobile
@include to(6) {

  .resp-table {

    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    td {
      display: flex;
      margin-bottom: 5px;
      @include rem(font-size, 12px);

      label {
        @include rem(font-size, 12px);
      }

      &::before {
        content: attr(data-label);
        flex: 0 0 auto;
        display: block;
        padding-top: 2px;
        width: 70px;
        font-weight: bold;
        text-transform: uppercase;
        @include rem(font-size, 10px);
        color: $medium-gray;
      }

      &:last-child {
        padding-bottom: 20px;
        border-bottom: 1px solid $hr-color;
      }

      &.resp-table-action,
      &.resp-table-edit-action {
        padding-top: 10px;

        &::before {
          font-size: 0;
        }
      }
    }

    &.-long-label {

      td {

        &::before {
          width: 110px;
          padding-right: 20px;
        }
      }
    }
  }
}

// desktop
.resp-table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;

  thead {
    background-color: #f5f6f7;
    @include rem(font-size, 10px);
    text-transform: uppercase;
    color: $dark-gray;

    th {

      @include from($logbook-1) {
        padding: 10px;
      }

      @include from($logbook-2) {
        padding: 17px 15px;
      }

      &.resp-table-course {
        min-width: 130px;
        text-align: left;
      }

      &.resp-table-action,
      &.resp-table-edit-action {
        font-size: 0;
      }
    }
  }

  &:not(.-bordered) {

    tbody {

      tr {

        &:last-child {

          td {
            border-bottom: 0;
          }
        }
      }
    }
  }

  &.-bordered {
    margin-bottom: 30px;
  }

  td {
    color: $black;
    @include rem(font-size, 12px);

    @include from($logbook-1) {
      padding: 10px;
      border-bottom: 1px solid $hr-color;
    }

    @include from($logbook-2) {
      padding: 20px 15px;
    }

    label {
      padding-top: 0;
      padding-bottom: 0;
      @include rem(font-size, 12px);

      @include from($logbook-2) {
        @include rem(font-size, 15px);
      }
    }

    input[type='text'],
    .clia-datepicker,
    select {
      margin: 0;
    }

    select {
      padding: 10px;

      @include from(6) {
        @include rem(font-size, 14px);
      }
    }

    &.resp-table-course,
    &.resp-table-shipname,
    &.resp-table-agencyname,
    &.resp-table-event {
      color: $dark-blue;

      @include from($logbook-2) {
        @include rem(font-size, 15px);
      }
    }
  }

  .resp-table-status {

    @include from($logbook-1) {
      width: 150px;
      text-align: left;
    }

    @include from(8) {
      width: 100px;
    }

    @include from(9) {
      width: 150px;
    }

    .status-progress {
      margin-right: 5px;

      @include from($logbook-1) {
        display: block;
        @include rem(font-size, 11px);
      }

      @include from(8) {
        display: block;
        @include rem(font-size, 12px);
      }

      &.-not-registered {
        color: $dark-gray;
      }
    }

    .status-date {
      font-weight: 400;

      @include from($logbook-1) {
        display: block;
        @include rem(font-size, 11px);
      }
    }
  }

  .resp-table-credits,
  .resp-table-score {

    @include from($logbook-1) {
      width: 75px;
      text-align: center;
    }
  }

  .resp-table-action {

    @include from($logbook-1) {
      width: 100px;
      text-align: center;
    }

    @include from($logbook-2) {
      width: 90px;
      padding-left: 0;
      padding-right: 0;
    }
  }


  .resp-table-shipname,
  .resp-table-date,
  .resp-table-location,
  .resp-table-ship-files,
  .resp-table-ship-status,
  .resp-table-edit-action,
  .resp-table-agencyname,
  .resp-table-rooms,
  .resp-table-resnum,
  .resp-table-event,
  .resp-table-id-type,
  .resp-table-clia-id,
  .resp-table-date-registered,
  .resp-table-date-completed {

    @include from($logbook-1) {
      padding-left: 5px;
      padding-right: 5px;
      text-align: left;
    }

    @include from(10) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .resp-table-shipname,
  .resp-table-agencyname {

    @include from($logbook-2) {
      width: 330px;
    }
  }

  .resp-table-event {

    @include from($logbook-1) {
      width: 300px;
    }

    @include from($logbook-2) {
      width: 450px;
    }
  }

  .resp-table-currency {

    @include from($logbook-1) {
      width: 140px;
    }
  }

  .resp-table-ship-files {

    .btn {
      max-width: 100px;
    }
  }

  .resp-table-edit-action {

    @include from($logbook-1) {
      width: 50px;
    }

    .edit-tools {

      .-edit,
      .-delete {

        &::before {

          @include from($logbook-1) {
            @include rem(font-size, 15px);
          }
        }
      }

      .-delete {

        @include from($logbook-1) {
          margin-left: 5px;
        }
      }
    }

    &.-text {

      @include from($logbook-1) {
        width: 150px;
      }

      .-edit,
      .-delete {
        @include rem(font-size, 12px);

        &::before {
          margin: 0 5px 0 10px;
        }
      }
    }
  }

  .has-course-part {
    margin-bottom: 0;
    background-color: lighten($hr-color, 18%);

    @include from($logbook-1) {
      background-color: $white;
    }

    td {
      border-bottom: 0;
    }
  }

  .course-part-row {
    margin-bottom: 0;
    background-color: lighten($hr-color, 18%);

    @include from($logbook-1) {
      background-color: $white;
    }

    &.last {

      td {

        @include from($logbook-1) {
          border-bottom: 1px solid $hr-color;
          padding-bottom: 40px;
        }
      }
    }

    td {
      border-bottom: 0;
      color: $dark-blue;

      @include from($logbook-1) {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &:first-child {
        padding-top: 20px;

        @include from($logbook-1) {
          padding-top: 10px;
          padding-left: 20px;
        }
      }
    }
  }

  .-passed {
    @include icon(check-circle) {
      color: $marina-blue;
      @include rem(font-size, 24px);
    };
  }

  .btn {

    &.small {
      padding: 5px 10px;

      @include from($logbook-1) {
        padding: 5px;
        @include rem(font-size, 10px);
      }

      @include from($logbook-2) {
        padding: 5px 10px;
        @include rem(font-size, 12px);
      }
    }

    @include from($logbook-1) {
      width: 100%;
    }
  }

  .shipname {
    display: block;
    width: 100%;
  }

  .parent-line {
    display: block;
    width: 100%;
    @include rem(font-size, 11px);
    font-weight: 400;
    color: $black;
  }

  .edit-tools {
    display: flex;

    .-delete {
      margin-left: 10px;
    }
  }

  .-no-results {
    margin: 0;
    @include rem(font-size, 15px);
    font-weight: 700;
    color: $dark-blue;
  }

  select {
    margin: 0;
  }

  // myclia tables
  &.-myclia {

    td,
    th {
      text-align: center;

      @include to(6) {
        text-align: left;
      }

      @include from(12) {
        padding-left: 10px;
        padding-right: 10px;
      }

      &::before {
        width: 110px;
      }

      &.resp-table-myclia-company-type,
      &.resp-table-myclia-company-name,
      &.resp-table-myclia-first-name,
      &.resp-table-myclia-last-name,
      &.resp-table-myclia-email, {
        text-align: left;
      }
    }

    .edit-tools {
      justify-content: center;
    }
  }

  .attachments-list {
    @include rem(font-size, 12px);

    p {
      display: flex;
      align-items: center;
      margin: 0;
      line-height: 1;

      a {
        @include rem(font-size, 12px);
        font-weight: 600;
      }
    }

    .-delete {
      margin-left: 10px;
      font-size: 0;

      &::before {
        @include rem(font-size, 15px);
      }
    }

    ul {
      @include kill-bullets;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        @include rem(font-size, 12px);
        font-weight: 600;

        a {
          display: inline;
          margin: 0 3px;
        }
      }
    }
  }

  &.-my-events {
    margin-bottom: 30px;

    th {
      text-align: left;
    }
  }
}
