.in-touch-contact{
	background-color: $light-blue-gray;	
	padding: 40px 0 20px;

	.top-section {
		border-bottom: 2px solid rgba($black, 0.1);
		margin-bottom: 20px;
		h2 {
			padding-bottom: 15px;
		}
	}

	.text-content {
		.item {
			margin-bottom: 20px;
			h1,h2,h3,h4,h5,h6 {
				margin-bottom: 10px;
				color: $light-red;
			}
			p {
				@include rem(font-size, 15px);
				a {
					font-weight: bold;
				}
			}
		}
	}

	@include from(8){
		padding: 60px 0 20px;

		h2 {
			margin-bottom: 0;
			padding-right: 10px;
		}

		.top-section {
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			align-items: baseline;
			margin-bottom: 40px;

		}

		.text-content{

			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;

			.item {
				width: 50%;
				padding: 0 20px 0 0;
			}

		}

	}

}