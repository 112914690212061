// Buttons
//
// :hover       - Hover state
// :focus       - Focus state
// :active      - Active state
// :disabled    - Disabled state
// .small       - Small Button
// .big         - Big Button
// .gray        - Gray Button
// .after-arrow - Arrow After
// .full-arrow  - Full Arrow
// .lock        - Lock Icon
// .lowercase   - Lowercase text
//
// Markup:
// <a class="btn {{modifier_class}}" href="#">Link Button</a>
// <button class="{{modifier_class}}">Standard Button</button>
// <input type="submit" class="{{modifier_class}}" value="Submit Button" />
//
// Styleguide 5.2
a.btn,
button,
input[type="submit"],
input[type="button"] {
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  background: $light-red;
  color: $white;
  text-align: center;
  @include rem(font-size, 13px);
  font-weight: 900;
  border: 2px solid $light-red;
  border-radius: $border-radius;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: background-color $ts $te, color $ts $te, border $ts $te;

  &:hover,
  &:active,
  &:focus {
    background-color: $white;
    color: $light-red;
    outline: 0;
    text-decoration: none;
  }

  &:disabled {
    opacity: .5;
    pointer-events: none;
    cursor: default;
  }

  &.small {
    @include rem(font-size, 12px);
  }

  &.tiny {
    @include rem(font-size, 10px);
  }

  &.big {
    @include rem(font-size, 14px);
  }

  &.pale {
    background-color: #fbddd9;
    color: $black;
    border-color: #fbddd9;

    &:hover,
    &:focus {
      background-color: $light-red;
      border-color: $light-red;
      color: $white;
    }
  }

  &.transparent {

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  &.gray {
    padding: 8px 20px;
    background-color: $alt-light-gray;
    color: $light-blue;
    border-color: $alt-light-gray;

    &:hover,
    &:focus{
      background-color: $white;
      border-color: $light-blue;
    }
  }

  &.dark-text {
    color: $medium-gray;
  }

  &.after-arrow {

    &::after {
      content: "\e903";
      font-family: $icon;
    }
  }

  &.full-arrow {
    width: 50px;
    height: 45px;
    color: $light-red;
    text-align: center;
    position: relative;
    overflow: hidden;
    border: 0;
    vertical-align: middle;

    @include from(8){
      height: 42px;
    }

    &::after{
      content: "\e903";
      font-family: $icon;
      position: absolute;
      color: $white;
      top: 50%;
      left: 0;
      right: 0;
      margin: auto;
      transform: translateY(-50%);
      @include rem(font-size, 14px);
      transition: color $ts $te;
    }

    &:hover,
    &:focus {

      &::after{
        color: $light-red;
      }
    }

  }


  &.lock {
    position: relative;
    padding-left: 55px;
    overflow: hidden;

    &::before {
      content: '\e915';
      background-color: #d8160c;
      color: $white;
      font-family: $icon;
      padding: 0 13px 0 15px;
      position: absolute;
      left: -2px;
      top: -2px;
      bottom: -2px;
      height: calc(100% + 4px);
      border-radius: $border-radius 0 0 $border-radius;
      display: flex;
      align-items: center;
      line-height: 0;
      margin: auto;
    }
  }

  &.lowercase {
    text-transform: none;
  }
}
