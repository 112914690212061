.verify-member {
  padding: 20px;
  margin: 0 0 30px;
  text-align: center;
  background: #f3f3f6;

  @include from(8) {
    padding: 40px;
    text-align: left;
  }

  @include from(8) {
    padding: 45px;
  }

  form {

    @include from(8) {
      padding-top: 10px;
    }

    @include from(10) {
      display: flex;

      input {
        flex: 1 1 auto;
        margin: 0;
      }

      input[type='submit'] {
        min-width: 180px;             
      }
    }
  }

  input[type="text"] {
    background-color: $white;
  }

  input[type="submit"] {
    width: 100%;

    @include from(8) {
      width: auto;
    }

    @include from(10) {
      margin-left: 10px;
    }
  }
}

.verify-member-results {

  @include from(8) {
    padding: 20px 0 0;
  }

  h3 {
    margin-bottom: 10px;
  }
}
