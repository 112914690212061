.announcement-wrapper {
	padding: 10px 0;
	background-color: $light-red;
	p {
		margin-bottom: 10px;
		@include rem(font-size, 14px);
		color: $white;
	}

	.btn {
		padding: 10px;
		background-color: darken($light-red, 10%);
	}

	@include from(5) {
		.inner {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 20px;
		}
		p {
			margin-bottom: 0;
		}
		.btn {
			margin-left: 10px;
		}
	}

}