.about-report {
	background-color: $light-blue-gray;
	padding-top: 40px;
	.report-context {
		p {
			margin-bottom: 0;
			a {
				font-weight: bold;
			}
		}
		hr {
			margin: 20px 0;
		}
	}
	.report-highlights{
		padding-top: 40px;
		.report-callout {
			max-width: 450px;
			margin: 0 auto 40px;
			img {
				border: 1px solid rgba($black, 0.2);
				width: 100%;
			}
			.report-content-box {
				width: calc(100% - 30px);
				margin: -60px auto 0;
				border-top: 3px solid $light-red;
				background-color: $white;
				z-index: +1;
				position: relative;
				padding: 40px 20px 80px;
				box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.05);
				h3 {
					line-height: 1.3;
				}
				p {

				}
				a{
					position: absolute;
					bottom: 4px;
					left: 4px;
					right: 4px;
					@include rem(font-size, 12px);
					text-transform: uppercase;
					padding: 10px 20px;
					background-color: #f8f8f8;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					font-weight: 900;
					transition: background $ts $te;
					&:after{
						@include rem(font-size, 11px);
					}
					&:hover,
					&:focus{
						background-color: darken(#f8f8f8, 3%);
					}
				}
			}
		}
	}
	.download-links {
		border-top: 1px solid rgba($black, 0.1);
		padding: 40px 0;
		margin-top: 20px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		.btn {

		}
		.social{
			margin: 0;
			padding: 0;
			list-style: none;
			display: none;
			li {
				display: inline-block;
				color: $medium-gray;
				font-weight: 900;
				@include rem(font-size, 13px);
				span {
					padding-right: 7px;
				}
				a {
					text-align: center;
					padding: 0 5px 0 10px;
					border-left: 1px solid rgba($medium-gray, 0.25);
					color: $light-gray;

					&:hover{
						text-decoration: none;
						color: $dark-gray;
					}
				}
			}
		}
		.after-arrow {
			@include rem(font-size, 12px);
			font-weight: 900;
			text-transform: uppercase;
			margin-left: auto;
			text-align: right;
			padding-left: 10px;
			padding-top: 5px;
			&:after{
				@include rem(font-size, 11px);
			}
		}
	}
	@include from(6) {
		.report-highlights {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			flex-direction: row;
			width: 100%;
			.report-callout {
				width: calc(50% - 15px);
				margin: 0 30px 40px 0;
				display: flex;
				flex-direction: column;
				&:nth-of-type(even){
					margin-right: 0;
				}
				.report-content-box {
					flex-grow: 1;
				}
			}
		}
		.download-links {

			.social {
				display: block;
				margin-left: 15px;
			}
		}
	}
	@include from(8){
		padding-top: 60px;
		.inner {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-bottom: 40px;
		}
		.text-context {
			width: 65%;
			padding-bottom: 20px;
			padding-right: 40px;
			margin-bottom: 0;
		}
		.report-context {
			width: 32%;
			margin-left: 0;
			padding-top: 20px;
		}
		.download-links {
			padding-bottom: 0;
		}
	}
	@include from(10){
		.report-highlights {
			.report-callout {
				width: calc(33% - 17px);
				&:nth-of-type(even){
					margin-right: 30px;
				}
				&:nth-of-type(3n){
					margin-right: 0;
				}
				.report-content-box {
					padding: 50px 30px 130px;
					a {
						padding-left: 30px;
						padding-right: 30px;
					}
				}
			}
		}
	}
}
