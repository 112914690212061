$rem-baseline: 16px !default;
$rem-fallback: true !default;
$rem-px-only: false !default;

// list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
@function rem-separator($list) {
  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, $value / ($rem-baseline / 1rem), $separator);
    } @else if type-of($value) == "list" {
      $result: append($result, rem-convert($to, $value...), $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return $result;
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property, $values in $properties {
      @include rem($property, $values...);
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}



// Clearfix - micro version
@mixin clearfix-micro {

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

//Vertical alignment
@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// Gradient
@mixin gradient($from, $to) {
  background: $from;
  background: linear-gradient(to bottom,  $from 0%, $to 100%);
}

// No bullets on ul's
@mixin kill-bullets($margin: 0, $padding: 0) {
  margin: $margin;
  padding: $padding;
  list-style: none;
}

// Font Smoothing
@mixin font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// transition mixin:

// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  -webkit-transition(width 0.2s, height 0.3s ease-in-out);
//          transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions
@mixin transition($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }

  transition: $unfoldedTransitions;
}

@function unfoldTransition ($transition) {
  // Default values
  $property: all;
  $duration: $ts;
  $easing: $te; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}



// Absolute positioning shortcut
//
// Usage:    .sample {
//             include absolute(10px, 10px, 5px, 15px);
//           }
//
// Output:   .sample{
//             position: absolute;
//             top: 10px;
//             right: 10px;
//             bottom: 5px;
//             left: 15px;
//           }
@mixin absolute($top:false, $right:false, $bottom:false, $left:false) {
  position: absolute;
  @if $top    { top: $top; }
  @if $left   { left: $left; }
  @if $bottom { bottom: $bottom; }
  @if $right  { right: $right; }
}


// Move objects shortcut
//
// Usage:    .sample {
//             include move(left, 5px);
//           }
//
// Output:   .sample{
//             position: relative;
//             left: 5px;
//           }
@mixin move($direction, $distance) {
  position: relative;
  @if ($direction == 'up')    { top: -$distance; }
  @if ($direction == 'right') { right: $distance; }
  @if ($direction == 'down')  { top: $distance; }
  @if ($direction == 'left')  { left: $distance; }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin blue-gradient-image-overlay {
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(19,27,77, 0.8); /* Old browsers */
    background: -moz-linear-gradient(-51deg, rgba(19,27,77,0.8) 0%, rgba(32,113,175,0.8) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-51deg, rgba(19,27,77,0.8) 0%,rgba(32,113,175,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(139deg, rgba(19,27,77,0.8) 0%,rgba(32,113,175,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#131b4d', endColorstr='#2071af',GradientType=0.8 ); /* IE6-9 fallback on horizontal gradient */

  }
  > div,
  > section {
    position: relative;
  }
}


@mixin icomoon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Icomoon
//
// Mixin to include Icomoon icons
//
// $icon     = null   - Name of the icon from the icons Sass map
// $position = before - Position of the icon, either before or after
// $styles   = true   - Boolean to output required Icomoon styling
//
// Definition:
// @mixin icon($icon: null, $position: before, $styles: true) {
//   &::#{$position} {
//     @if $icon {
//       content: "#{map-get($icons, $icon)}";
//     }
//     @if $styles {
//       font-family: 'icomoon' !important;
//       speak: none;
//       font-style: normal;
//       font-weight: normal;
//       font-variant: normal;
//       text-transform: none;
//       -webkit-font-smoothing: antialiased;
//       -moz-osx-font-smoothing: grayscale;
//     }
//     // Include any extra rules supplied for the pseudo-element
//     @content;
//   }
// }
//
// Usage:
// .facebook-link {
//   @include icon(facebook) {
//     color: sm-color(facebook);
//     &:hover,
//     &:focus {
//       color: #333;
//     }
//   };
// }
// .social-links {
//   a {
//     @include icon;
//     &.facebook {
//       @include icon(facebook, before, false);
//     }
//     &.twitter {
//       @include icon(twitter, before, false);
//     }
//   }
// }
//
// Compiled:
// .facebook-link::before {
//   content: "";
//   font-family: 'icomoon' !important;
//   speak: none;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   color: #3b5998;
// }
// .facebook-link::before:hover, .facebook-link::before:focus {
//   color: #333;
// }
// .social-links a::before {
//   font-family: 'icomoon' !important;
//   speak: none;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
// .social-links a.facebook::before {
//   content: "";
// }
// .social-links a.twitter::before {
//   content: "";
// }
//
// Styleguide 98.1
@mixin icon($icon: null, $position: before, $styles: true) {
  // Either a ::before or ::after pseudo-element, defaulting to ::before
  &::#{$position} {
    @if $icon {
      // A particular icon has been specified
      content: "#{map-get($icons, $icon)}";
    }
    @if $styles {
      @include icomoon;
    }
    // Include any extra rules supplied for the pseudo-element
    @content;
  }
}
