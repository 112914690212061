.page--myclia-account,
.cart-customer-info {

  .update-password {
    margin: 30px 0 20px;
  }

  .profile-name-container,
  .form-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  label {

    .info {
      letter-spacing: normal;
    }
  }

  .input {
    flex-basis: 100%;

    @include from(6) {
      flex-basis: 47%;

      &.-full {
        flex-basis: 100%;

        .input-inner {
          width: 47%;
        }
      }

      &.-text-full {

        flex-basis: 100%;

        input,
        select {
          width: 47%;
        }
      }
    }

    &.-text-split {
      width: 100%;
      margin: 10px 0;
      flex-basis: auto;

      @include from(8) {
        display: flex;
        justify-content: space-between;
      }
    }

    .optional {
      @include rem(font-size, 13px);
      letter-spacing: normal;
    }

    .text {
      @include rem(font-size, 15px);
      letter-spacing: normal;
    }

    p {
      @include rem(font-size, 14px);
      max-width: 100%;
    }
    + p {
      max-width: 100%;
    }
  }

  .submit-row {

    &.-right {
      text-align: right;
    }

    .-go-back {
      @include rem(font-size, 12px);
      margin: 0 10px;
      @include icon(chevron-down) {
        display: inline-block;
        margin-right: 3px;
        transform: rotate(90deg);
      };
    }
  }

  .profile-create-password {
    margin-bottom: 30px;
  }

  .tab-list {
    display: flex;
  }

  .profile-address-container {
    margin-bottom: 30px;

    .top-section {
      display: block;
  		border-bottom: 2px solid rgba($black, 0.1);
  		margin-bottom: 20px;
      max-width: 100%;

      @include from(5) {
        display: flex;
  			justify-content: space-between;
        align-items: center;
  			flex-direction: row;
      }

      @include to(4) {

        .tab-list {

          li {

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      h5 {
  			margin-bottom: 20px;
  			padding-right: 10px;
        @include rem(font-size, 13px);
        color: $dark-gray;
        letter-spacing: 1px;

        @include from(5) {

          margin-bottom: 0;
        }
  		}
  	}

    .tabbed-content {

      h3 {
        margin-bottom: 10px;
      }

      .radio-block {
        margin-bottom: 20px;
      }
    }
  }

  .address-block,
  .phone-block,
  .profile-create-password {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .checkbox-container {
    margin: -10px 0 20px 10px;
  }

  .phone-block,
  .clia-modal {
    margin-bottom: 30px;

    input[type='tel'] {

      @include between(6, 11) {

        &.phone-ext {
          display: block;
          margin-left: 0;
        }
      }

      &.-full-num {
        width: 135px;
      }
    }
  }

  .myclia-company-affiliation {
    @include clearfix-micro;
    margin-bottom: 30px;

    .error-message {
      margin-top: 10px;
    }

    .verify-member {
      margin-top: 15px;
    }

    form,
    .form-block {
      padding-top: 20px;
      display: flex;
      flex-wrap: nowrap;

      input {
        margin: 0;
      }
    }

    input[type='text'] {

      @include from(10) {
        border-radius: $border-radius 0 0 $border-radius;
      }
    }

    input[type='button'] {
      display: inline-block;
      padding: 10px 15px;
      text-decoration: none;
      background: $light-red;
      color: $white;
      text-align: center;
      @include rem(font-size, 13px);
      font-weight: 900;
      border: 2px solid $light-red;
      border-radius: $border-radius;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      transition: background-color $ts $te, color $ts $te, border $ts $te;

      @include from(10) {
        border-radius: 0 $border-radius $border-radius 0;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: $white;
        color: $light-red;
        outline: 0;
        text-decoration: none;
      }
    }

    .add-company {
      float: right;
      clear: both;
    }
  }

  .photo-uploader {

    li {
      list-style: none;
      font-weight: normal;
      margin: 0 0 20px;

      &::before {
        content:'\2022';
        display: block;
        position: relative;
        max-width: 0px;
        max-height: 0px;
        left: -20px;
        top: -4px;
        color: $marina-blue;
        font-size: 20px;
      }
    }
  }

  .photo-cropper-outer {
    height: auto;
    margin-bottom: 30px;

    &.-inactive {
      opacity: 0;
      height: 0;

      .photo-cropper-inner,
      .photo-cropper-example {
        height: 0;

        p,
        img {
          height: 0;
        }
      }
    }

    .photo-cropper-inner {
      margin: 30px 0 0;
      height: auto;

      p,
      img {
        height: auto;
      }

      &.-has-example {

        @include from(8) {
          display: flex;
          flex-direction: row-reverse;
          margin: 0;
        }
      }

      .croppie-container {

        @include from(8) {
          width: 50%;
        }
      }
    }

    .photo-cropper-example {
      margin: 0 0 50px;

      @include from(8) {
        margin: 25px 0 0;
        width: 50%;
      }

      p {
        margin-bottom: 5px;
        @include rem(font-size, 15px);
      }
    }
  }

  .photo-upload-text {
    display: none;
    margin-left: 10px;
    @include rem(font-size, 12px);

    &.-active {
      display: inline-block;
    }
  }

  .cropper-buttons button,
  .cropper-buttons .btn {
    @extend a.btn.gray;
    margin: 0 5px 20px 5px;
    background-color: #e7e8ed;
    border-color: #e7e8ed;
    color: $darkest-gray;
    text-transform: none;
    @include rem(font-size, 15px);
    letter-spacing: normal;

    &:hover,
    &:focus {
      color: $port-blue;
    }
  }

  .create-account-save {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    @include from(5) {
      flex-direction: row;
    }

    .or {
      display: inline-block;
      margin: 15px 0;
      @include rem(font-size, 15px);

      @include from(5) {
        margin: 0 15px;
      }
    }
  }

  .exp-m {
    width: 60px;
  }

  .exp-y {
    width: 70px;
    margin-left: 10px;
  }

  .csc {
    width: 170px;
  }

  .data-bind-full {
    width: 100%;

    &.-flex {

      @include from(6) {

        display: flex;

        input,
        select {
          margin: 0;
        }
      }

      // shame...
      input[type='submit'] {
        margin-left: 0 !important;

        @include from(6) {
          margin-left: 20px !important;
        }
      }
    }
  }

  .add-industry-id {
    margin: 0 0 30px;
    padding: 20px;
    background: #f3f3f6;

    @include from(8) {
      padding: 30px;
    }

    select,
    input[type='text'] {
      background-color: $white;

      @include from(6) {
        margin: 0;
      }
    }

    .form-block {
      margin-top: 10px;

      @include from(6) {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
      }
    }

    input[type='text'] {

      @include from(6) {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  .my-clia-save-profile {
    position: relative;
    z-index: 10;
  }

}
